import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/userProfile";

function PostAdsForm({ setOpenPost, postId, updatePostLikes, shortCode }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [likes, setLikes] = useState(20);

  const [disabled, setDisabled] = useState(false);

  let userData = useSelector((state) => state.userDetails.user);
  const activeUserData = useSelector((state) => state.userActiveReducer);

  const handleChange = (e) => {
    setLikes(e.target.value);

    if (e.target.value >= userData?.credits) {
      if (e.target.value > userData.credits) {
        setDisabled(true);
        return;
      }
    }

    if (e.target.value < 20) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      likes,
      igUsername: activeUserData.activeusername,
      userId: userData.id,
      postId,
      shortCode,
    };

    const url = `${process.env.REACT_APP_API_URL}/user/creditLikeOrders/addOrder`;

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    setIsLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        setIsLoading(false);
        toast.success(data.message);
        userData.credits -= likes;
        dispatch(setUser(userData));
        updatePostLikes(postId, parseInt(likes, 10)); // Update the likes count
        setOpenPost(false);
        setLikes(0);
      } else {
        setIsLoading(false);
        toast.error("Unable to buy likes");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to buy likes");

      console.error("Failed to buy likes", error);
    }
  };

  return (
    <div className="post-modal post-add-form">
      <div className="modal-content">
        <h3>Send extra likes</h3>
        <form noValidate="">
          <div className="input-group validation-group likes-input">
            <input
              id="likes-input-3325527824856063462"
              value={likes}
              onChange={handleChange}
              type="tel"
              name="likes"
              className="form-control placeholder-shown"
              placeholder="0"
              pattern="^[0-9]+(?:,[0-9]{3})*$"
              inputMode="numeric"
              autoComplete="off"
              data-bonus="true"
            />
            <label htmlFor="likes-input-3325527824856063462">likes</label>
            <span className="icon"></span>
          </div>
          <div className="summary">
            <table className="table">
              <tbody>
                <tr className="available">
                  <td>Available credits</td>
                  <td className="user-credits credits validation-group">
                    {userData?.credits}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="total">
                  <td>Total cost</td>
                  <th className="credits">{likes | 0}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="btn-group validation-group">
            <button
              type="button"
              className="btn btn-danger btn-credit"
              data-bs-toggle="modal"
              data-bs-target="#creditsModal"
              disabled={isLoading}
            >
              Buy credits<span className="loading"></span>
            </button>
            <button
              type="submit"
              className="btn btn-green"
              onClick={handleSubmit}
              disabled={isLoading || disabled}
            >
              {isLoading ? (
                <div className="spinner-border border-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  Send now<span className="loading"></span>
                </>
              )}
            </button>
          </div>
        </form>
        <button
          className="btn btn-close me-2 text-xl"
          onClick={() => setOpenPost(false)}
        >
          <i className="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
  );
}

export default PostAdsForm;
