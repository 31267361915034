import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";

function Pricing() {
  return (
    <section id="pricing" className="pricing bg-blue p-5">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p className="text-white py-5">Pricing</p>
        </header>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          speed={600}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 40,
            },

            1200: {
              slidesPerView: 3,
            },
          }}
          navigation
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">50</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>9.99<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">100</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>17.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">250</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>24.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">500</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>48.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">1,000</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>75.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">1,500</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>99.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">3,000</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>189.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">5,000</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>300.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="box">
              <h3 className="text-lead">
                <span className="display-1 fw-bolder">10,000</span> <br /> Likes per
                Post
                <br />
                <br />
                <span className="display-4">
                  <i className="bi bi-heart-fill text-danger"></i>
                </span>
              </h3>
              <hr />
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <hr />
              <div className="price mb-4">
                <sup>$</sup>450.00<span> / mo</span>
              </div>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div className="row gy-4 pb-5 mb-5" data-aos="fade-left">
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-in"
            data-aos-delay={100}
          >
            <div className="box">
              <h3 style={{ color: "#07d5c0" }}>50 Likes per Post</h3>
              <div className="price">
                <sup>$</sup>9.99<span> / mo</span>
              </div>
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-in"
            data-aos-delay={200}
          >
            <div className="box">
              <h3 style={{ color: "#65c600" }}>100 Likes per Post</h3>
              <div className="price">
                <sup>$</sup>17<span> / mo</span>
              </div>
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-in"
            data-aos-delay={300}
          >
            <div className="box">
              <h3 style={{ color: "#ff901c" }}>250 Likes per Post</h3>
              <div className="price">
                <sup>$</sup>24<span> / mo</span>
              </div>
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <Link to="/signup" className="btn-buy">
                Create Account
              </Link>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6"
            data-aos="zoom-in"
            data-aos-delay={400}
          >
            <div className="box">
              <span className="featured">Hot</span>
              <h3 style={{ color: "#ff0071" }}>500 Likes per Post</h3>
              <div className="price">
                <sup>$</sup>48<span> / mo</span>
              </div>
              <ul>
                <li>Unlimited posts </li>
                <li>Real likes from real users</li>
                <li>Free views on all videos</li>
                <li>Safe & secure</li>
                <li>24/7 customer support</li>
              </ul>
              <a href="/signup" className="btn-buy">
                Create Account
              </a>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  marginTop: 10,
                }}
              >
                30 Day Money Back Guarantee
                <br />
                Cancel Anytime
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Pricing;
