import React from "react";

function HeroMain() {
  return (
    <div>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">
                Real Instagram Followers & Likes, Delivered Automatically!
              </h1>
              <h2 data-aos="fade-up" data-aos-delay={400}>
              Why go out of your way to buy Instagram likes manually with every new upload? Our system detects new uploads within 60 seconds and sends you real likes from real users automatically.
              </h2>
              <div data-aos="fade-up" data-aos-delay={600}>
                <div className="text-center text-lg-start">
                  <a
                    href="/signup"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay={200}
            >
              <img src="./assets/img/hero-img.png" className="img-fluid" alt="hero" />
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
    </div>
  );
}

export default HeroMain;
