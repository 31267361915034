import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

function Header() {
  const isLoggedIn = localStorage.getItem("id") !== null;
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <header id="header" className="header header-scrolled fixed-top py-0 ">
        <Marquee style={{ background: "#5BD330", height: "40px" }}>
          <strong className="text-black fs-4">
            Get 500 Bonus Credits when you sign up now - Time Left:{" "}
            {formatTime(countdown)}
          </strong>
        </Marquee>
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between my-4">
          <a href="/" className="logo d-flex align-items-center ">
            <img src="./assets/img/logo.svg" alt="logo" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="/#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#features">
                  Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#pricing">
                  Pricing
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#faq">
                  FAQs
                </a>
              </li>
              <li>
                <Link className="getstarted" to="/login">
                  Log In
                </Link>
              </li>
              <li>
                <Link className="getstartedd" to="/signup">
                  Sign Up
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
