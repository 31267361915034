import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CreditsModal from "./CreditsModal";
import AddAccountModel from "./AddAccountModal";
import { useSelector } from "react-redux";

function TopNav() {

  const userData = useSelector((state) => state.userDetails.user);
  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      localStorage.removeItem("id");
      console.log("localStorage cleared");
      setTimeout(() => {
        navigate("/login");
        window.location.reload();
      });
    } catch (error) {
      console.error("Error while logging out:", error);
    }
  };
  
  return (
    <>
      <nav id="navbar-dash" className="navbar">
        <div className="container">
          <div className="content">
            <Link className="logo" to="/dashboard">
              <span title="AutomaticViral"></span>
            </Link>

            <div className="navbar-right">
              <a
                href="##"
                className="navbar-credits"
                data-bs-toggle="modal"
                data-bs-target="#creditsModal"
              >
                {" "}
                <i className="bi bi-heart-fill me-3 fs-4"></i>
                <span className="user-credits" data-value={userData.credits}>
                  {userData.credits}
                </span>
              </a>

              <div className="navbar-user dropdown open overlay ps-auto" >
                <a
                  href="##"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="bi bi-list me-3 fs-3"></i>
                  <span className="username">{userData.name}</span>
                </a>

                <ul className="dropdown-menu" style={{ zIndex: 1000 }}>
                  <li>
                    <a href="/my-settings">Account settings</a>
                  </li>
                  <li>
                    <a href="/my-billing">Billing</a>
                  </li>
                  <li>
                    <a href="/my-subscriptions">Subscriptions</a>
                  </li>
                  <li role="separator" className="divider"></li>
                  <li>
                    <a href="##" onClick={handleLogout} className="d-flex">
                      Log out
                      <i className="bi bi-box-arrow-in-right fs-3 me-3 align-self-end"></i>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <CreditsModal />
      <AddAccountModel />
    </>
  );
}

export default TopNav;
