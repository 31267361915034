import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function VerifyEmail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    setIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_API_URL}/user/user/verifyUserAndSendEmail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        console.log(result)
        if (result.status === "error") {
          toast.error(result.message);
          setIsLoading(false);
        } else if (result.status === "success") {
          toast.success("Verification email sent.");
          setIsLoading(false);
          navigate("/confirm-otp", { state: { email, otp: result.otp } });
        } else {
          toast.error("Unknown error occurred.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Verify Your Email | Automatic Viral</title>
        <meta
          name="description"
          content="Enter your email to verify and access your Automatic Viral account."
        />
      </Helmet>
      <header className="d-flex flex-wrap justify-content-center">
        <Link
          to={"/"}
          style={{
            backgroundColor: "#3964e7",
            display: "flex",
            justifyContent: "center",
          }}
          className="w-100 p-4"
        >
          <img
            src="./assets/img/logo.svg"
            alt="logo"
            style={{ maxWidth: "100%", height: "45px" }}
          />
        </Link>
      </header>
      <section id="verify-email-form" className="access center">
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center my-4 fw-bold" style={{ fontSize: 40 }}>
            Verify Your Email
          </h2>
          <form
            onSubmit={handleSubmit}
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <div className="form-group mt-3">
              <div className="input-group">
                <input
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="form-control placeholder-shown"
                  required
                />
              </div>
            </div>
            <div className="text-end">
              Already have an account ? {""}
              <Link to="/login" className="fs-3 link-primary">
                Sign In
              </Link>
            </div>

            <div className="btn-group w-100 mt-5">
              <button
                className="btn btn-green fs-3 fw-semibold"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border border-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send Verification Email"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default VerifyEmail;
