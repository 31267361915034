import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footer" className="footer bg-white">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 d-flex flex-column align-items-center footer-links mb-0 order-1">
              <h4>
                <a href="/#features">Features</a>
              </h4>
              <h4>
                <a href="/#pricing">Pricing</a>
              </h4>
              <h4>
                <a href="/#faq">FAQs</a>
              </h4>
            </div>
            <div className="col-lg-4 col-12 d-flex flex-column align-items-center footer-links order-2">
              <h4>
                <Link to="/terms-of-service">Terms of Service</Link>
              </h4>
              <h4>
                <Link to="/privacy-policy">Privacy</Link>
              </h4>
              <p className="text-center">
                Not endorsed or certified by Instagram. All Instagram TM logos
                and trademarks displayed on this application are property of
                Instagram.
              </p>
            </div>
            <div className="col-lg-4 col-12 order-md-3 order-0 mb-md-0 mb-5">
              <div className="d-flex justify-content-center">
                <Link className="getstarted" to="/login">
                  Log In
                </Link>
                <Link className="getstartedd" to="/signup">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-0">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>Automatic Viral</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
