import React from "react";
import { useSelector } from "react-redux";

function UpdateEmail() {
  const userData = useSelector((state) => state.userDetails.user);

  return (
    <form method="post" action="/my-settings" className="form typeUnlock">
      <div className="form-title">Account email</div>
      <div className="form-group">
        <div className="input-group">
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            value={userData.email}
            placeholder="Email address"
            required=""
            disabled
          />
        </div>
      </div>
      <button type="submit" className="btn btn-green mx" disabled="">
        Save email
      </button>
    </form>
  );
}

export default UpdateEmail;
