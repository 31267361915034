import React from "react";

function DeliverySettings({ open, onClose }) {
  if (!open) return;
  return (
    <div className="drawer">
      <div className="form-group container">
        <div className="headerr">
          <h1>Delivery settings</h1>
          <button className="btn btn-close" onClick={onClose}></button>
        </div>

        <div className="divider"></div>

        <div id="enable" className="setting toggle">
          <div className="title">
            <span className="icon">
              <i className="bi bi-heart"></i>
            </span>
            Automatic Likes
          </div>
          <div className="description">
            You can pause automatic likes and views if you need a break.
          </div>
          <label className="input-switch">
            <input type="checkbox" aria-label="Enable/Disable Likes" />
            <span></span>
          </label>
        </div>

        <div className="divider"></div>

        <div id="speed" className="setting">
          <div className="title">
            <span className="icon">
              <i className="bi bi-speedometer2"></i>
            </span>
            Delivery Speed
          </div>
          <div className="description">
            <div>
              Set how long it will take for your latest posts to receive all of
              its automatic likes and views.
            </div>
          </div>
          <div className="av-notify av-notify-top av-notify-center">
            <div className="notify notify-danger" data-auto-close="false">
              <div className="notify-container">
                <i className="icon bi bi-exclamation-lg"></i>
                For the time being likes speeds have been disabled. Likes are
                working and may take up to 24 hours to be delivered in full.
              </div>
            </div>
          </div>
          <div className="input-group input-select">
            <select
              href="#"
              name="speed"
              className="form-control"
              aria-label="Likes Speed"
            >
              <option data-id="1" selected="">
                Instant
              </option>
              <option data-id="2">30 Min</option>
              <option data-id="3">1 Hour</option>
              <option data-id="6">2 Hours</option>
              <option data-id="7">3 Hours</option>
              <option data-id="4">4 Hours</option>
            </select>
          </div>
        </div>

        <div className="divider"></div>

        <div id="range" className="setting">
          <div className="title">
            <span className="icon">
              <i className="bi bi-arrow-left-right"></i>
            </span>
            Irregular likes
          </div>
          <div className="description">
            Configure a range of how many likes and views you want to be
            delivered to your posts. We will randomly send likes within the
            range to make your likes appear more natural.
          </div>
          <div className="range">
            <div className="input">
              <span className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                <div
                  className="ui-slider-range ui-corner-all ui-widget-header"
                  style={{ left: "38.9899%", width: "10.404%" }}
                ></div>
                <span
                  tabIndex="0"
                  className="ui-slider-handle ui-corner-all ui-state-default"
                  style={{ left: "38.9899%" }}
                ></span>
                <span
                  tabIndex="0"
                  className="ui-slider-handle ui-corner-all ui-state-default"
                  style={{ left: "49.3939%" }}
                ></span>
              </span>
            </div>
            <div className="manual">
              <div className="input-group">
                <input
                  className="low form-control"
                  type="number"
                  value="396"
                  min="10"
                  max="1000"
                  aria-label="Likes min"
                />
              </div>
              <div className="input-group">
                <input
                  className="high form-control"
                  type="number"
                  value="499"
                  min="10"
                  max="1000"
                  arial-label="Likes max"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div id="update" className="btn-group d-flex justify-content-center">
          <button type="submit" className="btn green-btn btn-green" disabled="">
            Save<span className="loading"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeliverySettings;
