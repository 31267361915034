import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../config/constants";
import { setModalAccount } from "../../redux/actions/modalAccounts";
import SubscriptionModal from "./SubscriptionModal";
import { plans } from "../../utils/plans";

function SubscriptionTable() {
  const dispatch = useDispatch();
  const connectedAccounts = useSelector(
    (state) => state.userConnectedAccounts.connectedAccounts
  );
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleManageClick = (event, account) => {
    event.preventDefault();
    setSelectedAccount(account);
    dispatch(setModalAccount(account));
  };

  function addOneMonth(date) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return newDate;
  }
  
  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className="panel-content">
      <table className="table sub-table">
        <thead className="hidden-xs hidden-sm">
          <tr>
            <th>Instagram username</th>
            <th>Subscription</th>
            <th>Next billing date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {connectedAccounts?.map((account, index) => {
            const currentDate = account.accountPackageSubsriptionDate;
            const nextMonthDate = addOneMonth(currentDate);
            const formattedDate = formatDate(nextMonthDate);

            return (
              <tr key={index}>
                <td className="user-sub active">
                  <div className="profile">
                    <div className="avatar">
                      <img
                        src={`${BASE_URL}/proxy/${account.profilePic}`}
                        crossOrigin="anonymous"
                        alt="Profile Pic"
                      />
                    </div>
                    <div className="info">
                      <div className="username">{account?.igUsername}</div>
                      <div className="hidden-lg">
                        <span>
                          <em>{plans[0].description}</em>
                        </span>{" "}
                        • <span className="light">{plans[0].price}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="sub-info hidden-xs hidden-sm">
                  <div>
                    <em>{plans[0].description}</em>
                  </div>
                  <div className="light">{plans[0].price}</div>
                </td>
                <td className="billing-info hidden-xs hidden-sm">
                  <div>
                    <em>{formattedDate}</em>
                  </div>
                </td>
                <td className="actions">
                  <div className="d-inline-block">
                    <a
                      href="##"
                      className="btn btn-bordered d-flex justify-content-center align-items-center"
                      data-toggle="modal"
                      data-target="#subscriptionmodal"
                      onClick={(event) => handleManageClick(event, account)}
                    >
                      <i className="bi bi-gear me-2"></i>
                      <span className="hidden-xs hidden-sm">Manage</span>
                    </a>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <SubscriptionModal account={selectedAccount} />
    </div>
  );
}

export default SubscriptionTable;
