import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/userProfile";
import { setActiveUser } from "../redux/actions/activeUser";
import { setConnectedAccounts } from "../redux/actions/connectedAccounts";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const [isLoggedInn, setIsLoggedInn] = useState(false);
  const [userData, setUserData] = useState(null); // To store user data

  useEffect(() => {
    const token = localStorage.getItem("id");
    const inSubscription = JSON.parse(localStorage.getItem("inSubscription"));

    if (token) {
      if (inSubscription) {
        navigate("/dashboard");
      } else {
        navigate("/subscription");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (isLoggedInn) {
      if (userData && userData.inSubscription) {
        navigate("/dashboard");
      } else {
        navigate("/subscription");
      }
    }
  }, [isLoggedInn, navigate, location.pathname, userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", credentials.email);
    urlencoded.append("password", credentials.password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    setIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_API_URL}/user/user/login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          console.log(result.message); // Consider using toast for user feedback
          toast.error(result.message);
          setCredentials({
            email: "",
            password: "",
          });
          setIsLoading(false);
        } else if (result.status === "success") {
          console.log("successfully logged in", result);
          toast.success("Successfully logged in");
          setUserData(result.data); // Store user data
          setIsLoggedInn(true); // Set login status
          setCredentials({
            email: "",
            password: "",
          });
          setIsLoading(false);
          dispatch(setConnectedAccounts(result.data.connectedAccounts));
          localStorage.setItem("id", result.data.id);
          localStorage.setItem("inSubscription", result.data.inSubscription);
          dispatch(setUser(result.data));
          dispatch(
            setActiveUser({
              activeusername: result.data.defaultIgUsername,
              activeIgId: result.data.defaultIgId,
              profilePic: result.data.profilePic,
            })
          );
        } else {
          console.log("Unknown error occurred."); // Consider using toast for user feedback
          toast.error("Unknown error occurred.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error); // Consider using toast for user feedback
        setIsLoading(false);
      });
  };

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>Login to Automatic Viral | Access Your Dashboard</title>
        <meta
          name="description"
          content="Log into your Automatic Viral account to manage your preferences, view your progress, and adjust your settings for Instagram growth. Simple and secure access to your social media boosting tools."
        />
      </Helmet>
      <header className="d-flex flex-wrap justify-content-center">
        <Link
          to={"/"}
          style={{
            backgroundColor: "#3964e7",
            display: "flex",
            justifyContent: "center",
          }}
          className="w-100 p-4"
        >
          <img
            src="./assets/img/logo.svg"
            alt="logo"
            style={{ maxWidth: "100%", height: "45px" }}
          />
        </Link>
      </header>
      <section id="login" className="access center ">
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mt-4 fw-bold" style={{ fontSize: 40 }}>
            Login To Your Dashboard
          </h2>
          <form className="mx-md-5 mx-0 px-md-5 px-2" onSubmit={handleSubmit}>
            <div className="row mx-md-5 mx-0 px-md-5 px-2">
              <div className="col-md-12 col-sm-12 form-group mt-3">
                <div className="input-group">
                  <input
                    name="email"
                    type="text"
                    value={credentials.email}
                    onChange={onChange}
                    placeholder="Email"
                    className="form-control placeholder-shown"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12 form-group">
                <div className="input-group">
                  <input
                    name="password"
                    type="password"
                    value={credentials.password}
                    onChange={onChange}
                    placeholder="Password"
                    className="form-control placeholder-shown"
                  />
                </div>
              </div>
            </div>

            <div className="row mx-md-5 mx-0 px-md-5 px-2">
              <div className="col-md-6">
                <div className="form-check ms-3">
                  <input
                    className="form-check-input fs-3 "
                    type="checkbox"
                    id="flexCheckChecked"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    <span className="fs-3 fw-semibold">Keep Me Signed in</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6 text-end col-sm-12">
                <span>Don't Have an Account?</span>{" "}
                <Link to="/signup" className="fs-3 link-primary">
                  Sign Up
                </Link>
              </div>

              <div className="col-12 text-center mt-3">
                <Link to="/verify-email" className="fs-3 link-primary">
                  Forgot Password ?
                </Link>
              </div>
            </div>

            <div className="row mx-md-5 mx-0 px-md-5 px-2">
              <div className="col-12 col-sm-12">
                <div className="btn-group w-100 mt-5">
                  <button
                    className="btn btn-green fs-3 fw-semibold"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="spinner-border border-3" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        Log In
                        <span className="loading" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Login;
