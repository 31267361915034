import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { plans } from "../../utils/plans";

function AddAccountModel() {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const navigate = useNavigate();

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.userDetails.user);
  const [account, setAccount] = useState(null);
  const [credentials, setCredentials] = useState({
    igUsername: "",
  });

  const addAccount = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/user/connectedAccount/addRecord`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        igUsername: credentials.igUsername,
        userId: userData.id,
      }),
    };

    setIsLoading(true);

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status === "error") {
        console.log(data.message); // Consider using toast for user feedback
        toast.error(data.message);
        setCredentials({
          igUsername: "",
        });
        setIsLoading(false);
      } else if (data.status === "success") {
        console.log("jadhfghdsgfjsdgfjsdg", data.data); // Consider using toast for user feedback
        toast.success("Instagram account found");
        setAccount({
          igUsername: data.data.igUsername,
          igId: data.data.igId,
        });
        setCredentials({
          igUsername: "",
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);

        console.log("Unknown error occurred."); // Consider using toast for user feedback
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const hideModal = () => {
    const backdrops = document.querySelectorAll(".modal-backdrop");
    backdrops.forEach((backdrop) => backdrop.parentNode.removeChild(backdrop));
    document.body.classList.remove("modal-open");
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div
        id={account ? "manageSubModal" : "addUserSubModal"}
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a
                href="##"
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></a>
            </div>

            {account ? (
              <div className="modal-body">
                <div className="modal-top">
                  <span className="icon">
                    {/* <img
                        className="profile"
                        src={`${BASE_URL}/proxy/${account.profilePic}`}
                        crossOrigin="anonymous"
                        alt="Profile Pic"
                      /> */}
                  </span>
                  <div className="sub-title"></div>
                  <div className="title">Manage subscription</div>
                  <p>
                    After the subscription plan is changed, the changes to likes
                    being sent are instant. You will be billed for a new
                    subscription plan.
                  </p>
                </div>

                <form>
                  <div className="input-group input-dropdown input-subs">
                    <div className="dropdown w-100">
                      <a
                        href="#!"
                        className="user-billing-info"
                        data-toggle="dropdown"
                      >
                        <span className="sub-description">
                          <em>{plans[selectedPlan].description}</em>
                          <span className="hidden-xs">/ post</span>
                        </span>
                        <span className="sub-price">
                          {plans[selectedPlan].price}{" "}
                          <span className="">/month</span>
                        </span>
                        <span className="sub-current hidden-xs"></span>
                      </a>
                      <ul className="dropdown-menu">
                        {plans.map((plan, index) => (
                          <li key={index}>
                            <a
                              href="#!"
                              onClick={() => handlePlanSelect(index)}
                            >
                              <span className="sub-description">
                                <em>{plan.description}</em>{" "}
                                <span className="hidden-xs">/ post</span>
                              </span>
                              <span className="sub-price">
                                {plan.price}{" "}
                                <span className="hidden-xs">/ mo</span>
                              </span>
                              <span
                                className="sub-current hidden-xs"
                                style={{
                                  display:
                                    plans[selectedPlan].description ===
                                    plan.description
                                      ? "block"
                                      : "none",
                                }}
                              ></span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="sub-details">
                    <table className="table table-borderless">
                      <tbody>
                        <tr className="sub-price">
                          <td>Price / month</td>
                          <th className="pt-4">{plans[selectedPlan].price}</th>
                        </tr>
                        <tr className="billing-date">
                          <td>Next billing date</td>
                          <th className="pt-4">
                            {plans[selectedPlan].billingDate}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="btn-group w-100">
                    <button
                      onClick={() => {
                        hideModal();
                        navigate(
                          "/other-account-subscription?packageValue=" +
                            (selectedPlan + 1) +
                            "&defaultIgUsername=" +
                            account.igUsername
                        );
                      }}
                      className="btn btn-green btn-block pt-3"
                    >
                      Next<span className="loading"></span>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="modal-body">
                <div className="modal-top">
                  <span className="icon">
                    <i className="bi bi-instagram fs-1"></i>
                  </span>

                  <div className="title mt-5">Add new Instagram Account</div>
                  <p>
                    Type in Instagram username you want to add and proceed to
                    selecting a subscription.
                  </p>
                </div>

                <form
                  // id="igForm"
                  className="typeUnlock requiredOnly"
                  onSubmit={addAccount}
                >
                  <div className="input-group">
                    <span className="icon icon-ig">
                      <i className="bi bi-person-circle fs-3"></i>
                    </span>
                    <input
                      type="text"
                      name="igUsername"
                      value={credentials.igUsername}
                      onChange={onChange}
                      className="form-control placeholder-shown"
                      placeholder="Instagram username"
                      aria-placeholder="Instagram username"
                      required=""
                    />
                  </div>

                  <div className="btn-group vertical d-flex">
                    <button
                      type="submit"
                      className="btn btn-green btn-block green-btn"
                      value=""
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <button className="btn btn-green btn-block green-btn">
                          <div
                            className="spinner-border border-3"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </button>
                      ) : (
                        <>
                          Next<span className="loading"></span>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAccountModel;
