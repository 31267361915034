import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function CreditsModal() {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleChange = (event) => {
    const { credits, price } = JSON.parse(event.target.value);
    setSelectedPackage({ credits, price });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission which can cause page reload
    navigate("/payment/credits", { state: { selectedPackage } });
  };
  return (
    <div id="creditsModal" className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-top">
              <span className="icon">
                <i
                  style={{ color: "white" }}
                  className="bi bi-star-fill fs-1"
                ></i>
              </span>
              <div className="title">Buy credits</div>
              <p>
                Need more likes or views? Purchase credits to manually send
                additional likes to particular posts. 1 credit equals 1 like.
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-group list">
                {[
                  { credits: 2500, price: 10 },
                  { credits: 7500, price: 29 },
                  { credits: 15000, price: 57 },
                  { credits: 25000, price: 93 },
                  { credits: 50000, price: 180 },
                  { credits: 100000, price: 350 },
                ].map((pkg, index) => (
                  <label key={index} className="check mx">
                    <input
                      type="radio"
                      name="package"
                      value={JSON.stringify(pkg)}
                      onChange={handleChange}
                      checked={
                        selectedPackage &&
                        selectedPackage.price === pkg.price &&
                        selectedPackage.credits === pkg.credits
                      }
                      required
                    />
                    <span>
                      <em>{pkg.credits}</em> credits
                      <span className="dull pull-right">${pkg.price}</span>
                    </span>
                  </label>
                ))}
              </div>

              <div className="btn-group vertical w-100">
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                  className="btn btn-green btn-block"
                >
                  Proceed to Checkout
                  <span className="loading"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditsModal;
