import React from "react";
import SettingsHeader from "../../components/users/SettingsHeader";
import TopNav from "../../components/users/TopNav";
import UpdateEmail from "../../components/users/UpdateEmail";
import UpdatePassword from "../../components/users/UpdatePassword";
// import { useSelector } from "react-redux";

function AccountSettings() {
  // const userData = useSelector((state) => state.userDetails.user);
  // console.log(userData);
  return (
    <>
      <TopNav />
      <SettingsHeader />
      <div className="page container">
        <div id="content-account" className="content content-settings">
          <div className="panel panel-pad">
            <h1>Account settings</h1>
            <div className="panel-content">
              <hr className="lg" />
              <UpdateEmail />
              <hr className="lg" />
              <UpdatePassword />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountSettings;
