const nextChargeDate = new Date();

nextChargeDate.setMonth(nextChargeDate.getMonth() + 1);
const formattedNextChargeDate = `${nextChargeDate.toLocaleString("default", {
  month: "short",
})} ${nextChargeDate.getDate()}, ${nextChargeDate.getFullYear()}`;

export const plans = [
  {
    description: "50 likes & views",
    price: "$9.99 /month",
    priceVal: 9.99,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "100 likes & views",
    price: "$17.00 /month",
    priceVal: 17.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "250 likes & views",
    price: "$24.00 /month",
    priceVal: 24.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "500 likes & views",
    price: "$48.00 /month",
    priceVal: 48.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "1000 likes & views",
    price: "$75.00 /month",
    priceVal: 75.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "1500 likes & views",
    price: "$99.00 /month",
    priceVal: 99.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "3000 likes & views",
    price: "$189.00 /month",
    priceVal: 189.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "5000 likes & views",
    price: "$300.00 /month",
    priceVal: 300.00,
    billingDate: formattedNextChargeDate,
  },
  {
    description: "10000 likes & views",
    price: "$450.00 /month",
    priceVal: 450.00,
    billingDate: formattedNextChargeDate,
  },
];
