import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || null;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  if (!email) {
    toast.error("Unauthorized access.");
    navigate("/verify-email"); // Redirect to verify-email if email is not in state
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setIsLoading(true);

    // API call to change the password
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/user/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();
      if (result.status === "success") {
        toast.success("Password changed successfully!");
        navigate("/login"); // Redirect to login page after successful password change
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Change Password | Automatic Viral</title>
        <meta
          name="description"
          content="Set a new password for your Automatic Viral account."
        />
      </Helmet>
      <header className="d-flex flex-wrap justify-content-center">
        <Link
          to={"/"}
          style={{
            backgroundColor: "#3964e7",
            display: "flex",
            justifyContent: "center",
          }}
          className="w-100 p-4"
        >
          <img
            src="./assets/img/logo.svg"
            alt="logo"
            style={{ maxWidth: "100%", height: "45px" }}
          />
        </Link>
      </header>
      <section id="change-password" className="access center">
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mt-4 fw-bold" style={{fontSize: "40px"}}>Change Password</h2>
          <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "500px" }}>
            <div className="form-group mt-3">
              <div className="input-group">
                <input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                  className="form-control placeholder-shown"
                  required
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <div className="input-group">
                <input
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  className="form-control placeholder-shown"
                  required
                />
              </div>
            </div>
            <div className="btn-group w-100 mt-5">
              <button
                className="btn btn-green fs-3 fw-semibold"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border border-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
