import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ConfirmOTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);

  // Retrieve the email from the state passed via navigation
  const email = location.state?.email || null;

  useEffect(() => {
    // If email is not set, redirect to VerifyEmail page
    if (!email) {
      toast.error("Email not found. Please verify your email first.");
      navigate("/verify-email");
      return;
    }

    // Reset OTP values when component is mounted
    setOtp(new Array(6).fill(""));
  }, [email, navigate]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    // Update OTP value
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Move to the next input field if a value is entered
    if (element.value !== "" && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const newOtp = [...otp];
    
    // Handle paste and distribute values across OTP inputs
    paste.split('').forEach((char, idx) => {
      if (idx < 6) {
        newOtp[idx] = char;
      }
    });

    setOtp(newOtp);

    // Focus the last filled input box
    const lastFilledInput = document.querySelector(`.otp-field[data-index="${paste.length - 1}"]`);
    if (lastFilledInput) {
      lastFilledInput.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");

    if (otpValue.length !== 6) {
      toast.error("Please enter a 6-digit OTP.");
      return;
    }

    setIsLoading(true);

    // API call to verify OTP
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/user/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ otp: otpValue, email }),
      });

      const result = await response.json();
      if (result.status === "success") {
        toast.success("OTP Verified successfully!");
        navigate("/change-password", { state: { email: email } });
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Confirm OTP | Automatic Viral</title>
        <meta
          name="description"
          content="Enter the OTP sent to your email to verify your account."
        />
      </Helmet>
      <header className="d-flex flex-wrap justify-content-center">
        <Link
          to={"/"}
          style={{
            backgroundColor: "#3964e7",
            display: "flex",
            justifyContent: "center",
          }}
          className="w-100 p-4"
        >
          <img
            src="./assets/img/logo.svg"
            alt="logo"
            style={{ maxWidth: "100%", height: "45px" }}
          />
        </Link>
      </header>
      <section id="confirm-otp" className="access center">
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mt-4 fw-bold">Enter OTP</h2>
          <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "500px" }}>
            <div className="otp-inputs d-flex justify-content-center gap-3" onPaste={handlePaste}>
              {otp.map((data, index) => {
                return (
                  <input
                    className="otp-field"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    data-index={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    required
                  />
                );
              })}
            </div>
            <div className="btn-group w-100 mt-5">
              <button
                className="btn btn-green fs-3 fw-semibold"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border border-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Verify OTP"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default ConfirmOTP;
