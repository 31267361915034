import React, { useState } from "react";
import PostAdsForm from "./PostAdsForm";
import { BASE_URL } from "../../config/constants";

function SinglePost({ postData, updatePostLikes }) {
  const [openPost, setOpenPost] = useState(false);

  return (
    <div className={`post-col ${openPost ? "open" : ""}`} datatype="IMAGE">
      <div className="post">
        <div className="av-notify"></div>

        <img
          src={`${BASE_URL}/proxy/${postData.display_url}`}
          className="image"
          height={"100%"}
          alt="Post"
          crossOrigin="anonymous"
          style={{
            width: "auto",
            margin: "0 auto",
          }}
        />
        <div className="post-counts counts-desktop">
          <div className="center-counts">
            <div className="post-count post-count-likes">
              <span className="count">
                <img
                  src="/assets/img/post-likes-white.svg"
                  height="12"
                  width="13"
                  alt="likes"
                />
                {postData.edge_media_preview_like.count}
              </span>
            </div>
            <div className="post-count post-count-comments">
              <span className="count">
                <img
                  src="/assets/img/post-comments-white.png"
                  height="12"
                  width="13"
                  alt="comment"
                />
                {postData.edge_media_to_comment.count}
              </span>
            </div>
          </div>
        </div>

        <div className="post-bottom">
          <div className="post-status-group">
            {postData.totalLikesPurchased > 0 ? (
              <div className="post-status post-sent-likes done">
                <div
                  className="icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-tooltip-content-id="tooltip-likes-3325527824856063462"
                  data-original-title=""
                  title=""
                >
                  <i style={{ color: "black" }} className="fs-5 me-2"></i>
                  <div className="status"></div>
                </div>
                <span className="sent" data-sent="704">
                  {postData.totalLikesPurchased}
                </span>
              </div>
            ) : (
              ""
            )}

            <div
              className="post-status post-sent-views done"
              style={{ display: "none" }}
            >
              <div
                className="icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-tooltip-content-id="tooltip-views-3325527824856063462"
                data-bs-original-title=""
                title=""
              >
                <div className="status"></div>
              </div>
              <span className="sent" data-sent="0">
                {postData.edge_media_to_comment.count}
              </span>
            </div>

            <div
              id="tooltip-likes-3325527824856063462"
              style={{ display: "none" }}
            >
              <div className="tooltip-order-content">
                <span className="tooltip-order-auto-likes">
                  <b className="tooltip-order-amt">684</b> likes
                  <br />
                </span>
                <span className="tooltip-order-credits-likes">
                  <b className="tooltip-order-amt">20</b> likes (credits)
                  <br />
                </span>
              </div>
            </div>

            <div
              id="tooltip-views-3325527824856063462"
              style={{ display: "none" }}
            >
              <div className="tooltip-order-content">
                <span
                  className="tooltip-order-auto-views"
                  style={{ display: "none" }}
                >
                  <b className="tooltip-order-amt">0</b> views
                  <br />
                </span>
                <span
                  className="tooltip-order-credits-views"
                  style={{ display: "none" }}
                >
                  <b className="tooltip-order-amt">0</b> views (credits)
                </span>
              </div>
            </div>
          </div>

          <div className="post-actions">
            <button
              className="btn btn-green post-add pt-3"
              onClick={() => setOpenPost(true)}
            >
              <i className="bi bi-plus-lg fs-1"></i>
            </button>
          </div>
        </div>
      </div>

      <PostAdsForm
        setOpenPost={setOpenPost}
        postId={postData.id}
        updatePostLikes={updatePostLikes}
        shortCode={postData.shortcode}
      />
    </div>
  );
}

export default SinglePost;
