import React from "react";
import Header from "../components/common/Header";
import HeroMain from "../components/main/HeroMain";
import OurValues from "../components/main/OurValues";
import Footer from "../components/common/Footer";
import Services from "../components/main/Services";
import Pricing from "../components/main/Pricing";
import Testimonials from "../components/main/Testimonials";
import FAQ from "../components/main/FAQ";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>
          Automatic Viral | Get Real Instagram Likes & Followers Automatically -
          Boost Your Social Media
        </title>
        <meta
          name="description"
          content="Meta Description: Boost your Instagram profile with Automatic Viral's real likes and followers! Experience effortless growth with our system that detects new uploads in seconds and delivers dynamic likes and matching views. Unlimited posts, natural-looking engagement, and a money-back guarantee. Elevate your social media presence today!"
        />
      </Helmet>
      <Header />
      <HeroMain />
      <div id="main">
        <Services />
        <OurValues />
        <Pricing />
        <Testimonials />
        <FAQ />
        {/* <Contactus /> */}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
