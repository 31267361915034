import React, { useState } from "react";
import { BASE_URL } from "../../config/constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setConnectedAccounts } from "../../redux/actions/connectedAccounts";
import { setUser } from "../../redux/actions/userProfile";
import { setActiveUser } from "../../redux/actions/activeUser";
import { plans } from "../../utils/plans";
import DeliverySettings from "./DeliverySettings";

function SubscriptionModal({ account }) {
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    igUsername: "",
  });

  const [isDeliverySettingsOpen, setDeliverySettingsOpen] = useState(false);

  const toggleDeliverySettings = () => {
    setDeliverySettingsOpen(!isDeliverySettingsOpen);
  };
  const closeDeliverySettings = () => {
    setDeliverySettingsOpen(false); // Specifically set to false
  };

  console.log(account);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const activeUserData = useSelector((state) => state.userActiveReducer);
  const userId = useSelector((state) => state.userDetails.user.id);
  const nextChargeDate = new Date();

  nextChargeDate.setMonth(nextChargeDate.getMonth() + 1);
  const formattedNextChargeDate = `${nextChargeDate.toLocaleString("default", {
    month: "short",
  })} ${nextChargeDate.getDate()}, ${nextChargeDate.getFullYear()}`;

  const activeAccountUsername = useSelector(
    (state) => state.userActiveReducer.activeusername
  );

  let connectedActiveAccount = useSelector((state) =>
    state.userConnectedAccounts.connectedAccounts.filter(
      (acc) => acc.igUsername === activeAccountUsername
    )
  );

  connectedActiveAccount = connectedActiveAccount[0];

  const [selectedPlan, setSelectedPlan] = useState(
    parseInt(connectedActiveAccount.accountPackageId) - 1
  );

  if (!account) return null;

  const handlePlanSelect = (plan) => {
    console.log(plan);
    setSelectedPlan(plan);
  };

  const hideModal = () => {
    const backdrops = document.querySelectorAll(".modal-backdrop");
    backdrops.forEach((backdrop) => backdrop.parentNode.removeChild(backdrop));
    document.body.classList.remove("modal-open");
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/user/connectedAccount/updateUsername`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        userId,
        oldIgUsername: account?.igUsername,
        igUsername: credentials?.igUsername,
      }),
    };

    setIsLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.status === "success") {
        console.log("Change Username DAta", data.data);
        dispatch(setConnectedAccounts(data.data.connectedAccounts));
        dispatch(setUser(data.data));
        dispatch(
          setActiveUser({
            activeusername: data.data.defaultIgUsername,
            activeIgId: data.data.defaultIgId,
            profilePic: data.data.profilePic,
          })
        );
        window.location.href =
          "/other-account-subscription?packageValue=1&defaultIgUsername=" +
          credentials?.igUsername;
      } else {
        // Handle failure scenario, maybe show an error message
        console.error("Update failed:", data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to update username:", error);
    }
  };

  return (
    <>
      <div id="subscriptionmodal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-top">
                <span className="icon">
                  <img
                    className="profile"
                    src={`${BASE_URL}/proxy/${account.profilePic}`}
                    crossOrigin="anonymous"
                    alt="Profile Pic"
                  />
                </span>
                <div className="username">
                  <span className="fw-semibold fs-2">{account.igUsername}</span>
                </div>
                <div className="title">Manage account</div>
              </div>

              <div className="btn-group menu vertical btn-manage">
                <button
                  type="button"
                  className="btn btn-light btn-bordered btn-block d-flex justify-content-between align-items-center btn-custom-border"
                  data-toggle="modal"
                  data-target="#igUserModal"
                >
                  Change username<i className="bi bi-chevron-right"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-light btn-bordered btn-block d-flex justify-content-between align-items-center"
                  data-toggle="modal"
                  data-target="#manageSubModal"
                >
                  Manage subscription<i className="bi bi-chevron-right"></i>
                </button>

                <a
                  href="##"
                  className="btn btn-light btn-bordered btn-block d-flex justify-content-between align-items-center"
                  title="Delivery settings"
                  onClick={toggleDeliverySettings}
                >
                  <span className="description">Buy followers</span>
                  <i className="bi bi-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="igUserModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ width: "max-content" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-top">
                <span className="icon">
                  <img
                    className="profile"
                    src={`${BASE_URL}/proxy/${account.profilePic}`}
                    crossOrigin="anonymous"
                    alt="Profile Pic"
                  />
                </span>
                <div className="title">Change username</div>
                <p>
                  You may only change your Instagram username{" "}
                  <br className="hidden-xs" />
                  once every 48 hours.
                </p>
              </div>

              <form className="typeUnlock">
                <div className="input-group">
                  <span className="icon icon-ig">
                    <i className="bi bi-person-circle fs-3"></i>
                  </span>
                  <input
                    name="igUsername"
                    type="text"
                    value={credentials.igUsername}
                    onChange={onChange}
                    className="form-control placeholder-shown"
                    placeholder="Instagram username"
                    aria-placeholder="Instagram username"
                    required
                  />
                </div>

                <div className="btn-group vertical w-100">
                  <button
                    className="btn btn-green green-btn btn-block"
                    onClick={updateUser}
                  >
                    {isLoading ? (
                      <div className="spinner-border border-3" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        Save<span className="loading"></span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="manageSubModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a
                href="#!"
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ width: "max-content" }}
              ></a>
            </div>
            <div className="modal-body">
              <div className="modal-top">
                <span className="icon">
                  <img
                    className="profile"
                    src={`${BASE_URL}/proxy/${activeUserData.profilePic}`}
                    crossOrigin="anonymous"
                    alt="Profile Pic"
                  />
                </span>
                <div className="sub-title"></div>
                <div className="title">Manage subscription</div>
                <p>
                  After the subscription plan is changed, the changes to likes
                  being sent are instant. You will be billed for a new
                  subscription plan.
                </p>
              </div>

              <form>
                <div className="input-group input-dropdown input-subs">
                  <div className="dropdown w-100">
                    <a
                      href="#!"
                      className="user-billing-info"
                      data-toggle="dropdown"
                    >
                      <span className="sub-description">
                        <em>{plans[selectedPlan].description}</em>
                        <span className="hidden-xs">/ post</span>
                      </span>
                      <span className="sub-price">
                        {plans[selectedPlan].price}{" "}
                        <span className="">/month</span>
                      </span>
                      <span className="sub-current hidden-xs"></span>
                    </a>
                    <ul className="dropdown-menu">
                      {plans.map((plan, index) => (
                        <li key={index}>
                          <a href="#!" onClick={() => handlePlanSelect(index)}>
                            <span className="sub-description">
                              <em>{plan.description}</em>{" "}
                              <span className="hidden-xs">/ post</span>
                            </span>
                            <span className="sub-price">
                              {plan.price}{" "}
                              <span className="hidden-xs">/ mo</span>
                            </span>
                            <span
                              className="sub-current hidden-xs"
                              style={{
                                display:
                                  plans[selectedPlan].description ===
                                  plan.description
                                    ? "block"
                                    : "none",
                              }}
                            ></span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="sub-details">
                  <table className="table table-borderless">
                    <tbody>
                      <tr className="sub-price">
                        <td style={{ borderTop: "none" }}>Price / mo</td>
                        <th className="pt-4">{plans[selectedPlan].price}</th>
                      </tr>
                      <tr className="billing-date">
                        <td>Next billing date</td>
                        <th className="pt-4">{formattedNextChargeDate}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="btn-group w-100">
                  <button
                    onClick={() => {
                      hideModal();
                      navigate(
                        "/other-account-subscription?packageValue=" +
                          (selectedPlan + 1) +
                          "&defaultIgUsername=" +
                          activeUserData?.activeusername
                      );
                    }}
                    className="btn btn-green btn-block pt-3"
                  >
                    Next<span className="loading"></span>
                  </button>
                </div>
                <div className="btn-group w-100">
                  <button
                    type="button"
                    className="btn btn-red-outline btn-block mx"
                    data-toggle="modal"
                    data-target="#cancelModal"
                  >
                    Cancel subscription
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <DeliverySettings open={isDeliverySettingsOpen}  onClose={closeDeliverySettings} />
    </>
  );
}

export default SubscriptionModal;
