import React from "react";

function OurValues() {
  return (
    <section id="features" className="values bg-white pt-3">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p>Why should you use AutomaticViral?</p>
        </header>
        <div className="row">
          <div className="col-md-3" data-aos="fade-up" data-aos-delay={200}>
            <div className="box">
              <img
                src="assets/img/process1.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Real users, Real likes.</h3>
              <p>
                AutomaticViral is the first and only service that provides real
                likes from real users.{" "}
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <div className="box">
              <img
                src="assets/img/process2.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Unlimited posts</h3>
              <p>
                Say goodbye to daily post limits. We&apos;ve upgraded your
                subscription to unlimited monthly posts free of charge!
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process3.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Automatic detection</h3>
              <p>
                New uploads are usually detected within 30 seconds of upload.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process5.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Dynamic likes</h3>
              <p>
                Not every post will get the same amount of likes. We
                occasionally send 5-10% more likes to make sure your posts
                maintain a natural look.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process4.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Matching views</h3>
              <p>
                Uploaded a video? We&apos;ll send you the same amount of views
                as likes, completely free.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process6.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Delay your likes</h3>
              <p>
                Likes coming in too fast? AutomaticViral lets you adjust the
                speed.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process8.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Money back guarantee</h3>
              <p>
                If for any reason you aren&apos;t satisfied with our service
                within 30 days, contact us for a full refund, no questions
                asked.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <div className="box">
              <img
                src="assets/img/process7.png"
                className="img-fluid"
                alt="process"
              />
              <h3>Cancel at any time</h3>
              <p>
                Cancel for any reason, any time. No contracts or long term
                commitments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurValues;
