import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { userActiveReducer } from "./userActiveReducer";
import { userConnectedAccounts } from "./userConnectedAccounts";
import { modalAccountReducer } from "./modalAccountReducer";
const reducers = combineReducers({
    userDetails: userReducer,
    userActiveReducer: userActiveReducer,
    userConnectedAccounts: userConnectedAccounts,
    modalAccountReducer: modalAccountReducer,

});

export default reducers

