import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

function TermsofService() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <section className="container text-dark mt-5 fs-4">
        <div className="text-center my-5">
          <div className="py-3">
            <h1 className="fw-bold">Terms of Service</h1>
            <p>Published on October 23, 2023</p>
          </div>
        </div>
        <div className="section__body">
          <div className="shell shell--medium">
            <h4 className="fw-bold">Terms of Use</h4>
            <p className="text-center">
              Thanks for using our products and services (“Services”). The
              Services are provided by Social Services LLC, +1 855-848-9812. By
              accessing this website, you agree to be bound by this website’s
              Terms and Conditions of Use, all applicable laws, and regulations,
              and agree that you are responsible for compliance with any
              applicable local laws. If you do not agree with any of these
              terms, you are prohibited from using or accessing this site. The
              materials contained in this website are protected by applicable
              copyright and trademark law.
            </p>
            <h4 className="fw-bold pt-2">Use License</h4>
            <p>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Automatic Viral’s website for only
              personal, non-commercial transitory viewing. This is the grant of
              a license, not a transfer of title, and under this license, you
              may not:
            </p>
            <ul className="ms-5 pb-2">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Automatic Viral’s website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or “mirror” the
                materials on any other server.
              </li>
            </ul>
            <p>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Automatic Viral at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>
            <h4 className="fw-bold pt-2">Disclaimer</h4>
            <p>
              The materials on Automatic Viral’s website are provided “as is”. Automatic Viral
              makes no warranties, expressed or implied, and hereby disclaims
              and negates all other warranties, including without limitation,
              implied warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property
              or other violation of rights. Further, Automatic Viral does not warrant or
              make any representations concerning the accuracy, likely results,
              or reliability of the use of the materials on its Internet website
              or otherwise relating to such materials or on any sites linked to
              this site.
            </p>
            <h4 className="fw-bold pt-2">Limitations</h4>
            <p>
              In no event shall Automatic Viral or its suppliers be liable for any
              damages (including, without limitation, damages for loss of data
              or profit, or due to business interruption,) arising out of the
              use or inability to use the materials on Automatic Viral’s Internet site,
              even if Automatic Viral or a Automatic Viral’s authorized representative has been
              notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied
              warranties, or limitations of liability for consequential or
              incidental damages, these limitations may not apply to you.
            </p>
            <h4 className="fw-bold pt-2">Billing</h4>
            <p>
              Payments on Automatic Viral are processed using a secure and verified
              payment gateway, with no sensitive financial information input
              through our website. The secure method of accepting payments is
              processed using 256-bit SSL encryption. By purchasing our services
              you are explicitly agreeing that you clearly understand and agree
              to what you are purchasing and will not file a fraudulent dispute
              via your credit card or debit card institution. Furthermore, you
              have authorization to use the credit card, debit card, or any
              other payment source used to make the purchase. Upon a fraudulent
              attempt to file a dispute or chargeback, we receive the right, if
              necessary, to reset all followers and likes, terminate your
              account, and/or permanently ban your IP address. We will also
              submit all evidence on file to the other party. Users acknowledge
              chargebacks, disputes, or payment reversals will not be carried
              out prior to discussing the situation with our support team.
            </p>
            <h4 className="fw-bold pt-2">Revisions and Errata</h4>
            <p>
              The materials appearing on Automatic Viral’s website could include
              technical, typographical, or photographic errors. Automatic Viral does not
              warrant that any of the materials on its website are accurate,
              complete, or current. Automatic Viral may make changes to the materials
              contained on its website without notice. Automatic Viral does not,
              however, make any commitment to update the materials.
            </p>
            <h4 className="fw-bold pt-2">Links</h4>
            <p>
              Automatic Viral has not reviewed all of the sites linked to its Internet
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by
              Automatic Viral of the site. Use of any such linked website is at the
              user’s own risk.
            </p>
            <h4 className="fw-bold pt-2">Site Terms of Use Modifications</h4>
            <p>
              Automatic Viral may revise these Terms of Use for its website without
              notice. By using this website, you agree to be bound by the then
              current version of these Terms and Conditions of Use.
            </p>
            <h4 className="fw-bold pt-2">Governing Law</h4>
            <p>
              Any claim relating to Automatic Viral’s website shall be governed by the
              laws of the UAE without regard to its conflict of law provisions.
              General Terms and Conditions apply to the use of a website.
            </p>
            <h4 className="fw-bold pt-2">Support</h4>
            <p>
              We strive to provide our users with industry-leading support. For
              any sales or technical questions, please email us at
              support[at]Automatic Viral[dot]com. We will be happy to assist you with
              any questions you may have.
            </p>
            <h4 className="fw-bold pt-2">Service</h4>
            <ol className="ms-5 pb-2">
              <li>
                Automatic Viral is not affiliated with Instagram, Facebook, Meta, or any
                Instagram third-party partners in any way.
              </li>
              <li>
                It is your sole responsibility to comply with Instagram rules
                and any legislation that you are subject to. You use Automatic Viral at
                your own risk.
              </li>
              <li>
                We are not responsible for your actions and their consequences.
                We are not to blame if your Instagram account is banned for any
                reason.
              </li>
              <li>
                We require your Instagram username to obtain the required
                information for the Instagram API. We do not store, give away,
                or otherwise, distribute your username to any third parties.
              </li>
              <li>
                While we can’t guarantee the exact number of followers, likes,
                or views you’ll receive, we do our utmost to meet your
                expectations. In fact, 99.9% of the time we do. If your services
                are not fully delivered, just contact our support team and we
                will make it right.
              </li>
              <li>
                We can’t guarantee the services’ continuous, uninterrupted, or
                error-free operability.
              </li>
              <li>
                It is the sole responsibility of the customers to ensure their
                accounts are set to “public” (and do not change usernames)
                during their use of the Automatic Viral service. Any downtime of service
                in relation to a client changing their profile to “private” will
                not result in any payment reimbursement for that period of time.
              </li>
              <li>
                By purchasing our service, you affirm that you clearly
                understand what you are buying and agree not to file a
                fraudulent dispute via your payment processor. Be aware that any
                chargeback will result in a permanent ban from using our
                services, and we will take measures to remove any services
                previously provided to you.
              </li>
              <li>
                We reserve the right to modify, suspend or withdraw the whole or
                any part of our service or any of its content without notice and
                without incurring any liability.
              </li>
              <li>
                It is your sole responsibility to check whether the Terms have
                changed.
              </li>
            </ol>
            <h3 className="fw-bold pt-2">Refunds</h3>
            <h4 className="fw-bold pt-2">General Policy</h4>
            <p>
              Customers who are not satisfied with our Instagram likes,
              followers, views, or comments services may request a full refund
              within the first 30 calendar days from the date of the initial
              purchase. Refund requests must be initiated through our customer
              support agents, accessible via email at
              support@automaticviral.co.
            </p>
            <h4 className="fw-bold pt-2">Exclusions</h4>
            <p>The following scenarios are not eligible for a refund:</p>
            <ol className="ms-5 pb-2">
              <li>Free credits or promotional services.</li>
              <li>Services that have been fully delivered and confirmed.</li>
              <li>Accounts that have violated our Terms of Service.</li>
            </ol>
            <h4 className="fw-bold pt-2">Process</h4>
            <ol className="ms-5 pb-2">
              <li>
                To initiate a refund request, contact our customer support
                agents with your order number and a detailed explanation for the
                refund request.
              </li>
              <li>
                Our customer support team will review the request and will
                inform you within 3-5 business days whether it has been approved
                or denied.
              </li>
              <li>
                If approved, the refund will be issued to your original payment
                method, generally within 7-10 business days, depending on your
                financial institution’s processing time.
              </li>
            </ol>
            <h4 className="fw-bold pt-2">Right to Deny Refund</h4>
            <p>
              Automatic Viral reserves the right to deny refund requests under the
              following circumstances:
            </p>
            <ol className="ms-5 pb-2">
              <li>Any attempt to abuse or exploit the refund policy.</li>
              <li>
                Multiple refund requests by the same customer for the same or
                different services.
              </li>
              <li>
                Failure to adhere to all terms and conditions outlined in this
                Refunds section and other sections of our Terms of Service.
              </li>
            </ol>
            <h4 className="fw-bold pt-2">Service-Specific Guidelines</h4>
            <p>
              Once the ordered Instagram likes, followers, views, or comments
              have been fully delivered and confirmed, these services are not
              eligible for a refund. If you encounter any issues with the
              delivery or quality of these services, please contact our customer
              support for assistance before the 30-day period expires.
            </p>
            <h4 className="fw-bold pt-2">Inquiries</h4>
            <p>
              All inquiries regarding this Refund Policy should be directed to
              our customer support team via email at support@automaticviral.co.
            </p>
            <p>
              By making a purchase on Automatic Viral, you agree to this Refund Policy
              in its entirety. Automatic Viral reserves the right to modify or update
              this policy at any time without prior notice.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TermsofService;
