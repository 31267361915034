import React from 'react'
import TopNav from '../../components/users/TopNav'
import CheckoutFormUser from '../../components/users/CheckoutFormUser'
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function OtherSubscription() {

  const query = useQuery();
  const packageValue = query.get('packageValue');
  const defaultIgUsername = query.get('defaultIgUsername');

  return (
    <div>
      <TopNav/>
      <CheckoutFormUser packageValue={packageValue} igUsername={defaultIgUsername} />
    </div>
  )
}

export default OtherSubscription
