import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Testimonials() {
  return (
    <section id="testimonials" className="testimonials bg-white pt-5">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p>
            What people say <br /> about AutomaticViral
          </p>
        </header>
        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={"auto"}
            speed={600}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 40,
              },

              1200: {
                slidesPerView: 3,
              },
            }}
            navigation
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                </div>
                <p>
                  The best thing about AutomaticViral is how much you can
                  customize. I can control the speed of incoming likes, send
                  additional likes, and turn off likes altogether. Moreover, the
                  likes are coming from real accounts and are always delivered
                  in a timely manner. It&apos;s given our business the necessary
                  boost to grow our Instagram account.
                </p>
                <div className="profile mt-auto">
                  <img
                    src="assets/img/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt="testimonial"
                  />
                  <h3>
                    <a
                      href="https://www.instagram.com/miamibunny/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @miamibunny
                    </a>
                  </h3>
                  <h4>43.3k followers</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                </div>
                <p>
                  Automatic Viral is a gem! Their exceptional customer service
                  and prompt support stand out, always ready to assist with any
                  inquiries or billing matters, prioritizing customer
                  satisfaction. Having been with them for a year, I've
                  experienced unparalleled organic growth on social media thanks
                  to their services. AV is truly the best, fostering significant
                  online presence growth. I highly recommend them; you're likely
                  to become a lifelong fan, just as I have.
                </p>

                <div className="profile mt-auto">
                  <img
                    src="assets/img/testimonials/testimonials-1.png"
                    className="testimonial-img"
                    alt="testimonial"
                  />
                  <h3 style={{ filter: "blur(5px)" }}>@Username</h3>
                  <h4>51k followers</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                </div>
                <p>
                  I’ve tried a lot of automatic like services and none are as
                  reliable and quick as AutomaticViral. They even give you a
                  personal dashboard! No other service comes close, highly
                  recommended
                </p>
                <div className="profile mt-auto">
                  <img
                    src="assets/img/testimonials/testimonials-2.png"
                    className="testimonial-img"
                    alt="testimonial"
                  />
                  <h3>
                    <a
                      href="https://www.instagram.com/gt3ad/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @gt3ad
                    </a>
                  </h3>
                  <h4>11.8k followers</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                  <i className="bi bi-star-fill" />
                </div>
                <p>
                  I've been using your service for about a year now. Likes and
                  views always work wonderfully, I am very pleased.
                </p>
                <div className="profile mt-auto">
                  <img
                    src="assets/img/testimonials/testimonials-3.jpg"
                    className="testimonial-img"
                    alt="testimonial"
                  />
                  <h3>
                    <a
                      href="https://www.instagram.com/artem85ways/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @artem85ways
                    </a>
                  </h3>
                  <h4>23.1k followers</h4>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="swiper-pagination" />
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
