import React from 'react'
import TopNav from '../../components/users/TopNav'
import CheckoutForm from '../../components/users/CheckoutForm'

function CheckoutCredits() {
  return (
    <div>
      <TopNav/>
      <CheckoutForm/>
    </div>
  )
}

export default CheckoutCredits
