import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TopNav from "../../components/users/TopNav";
import InfoNav from "../../components/users/InfoNav";
import AllPosts from "../../components/users/AllPosts";
import { useNavigate } from "react-router-dom";

function Usersmain() {
  const { user } = useSelector((state) => state.userDetails);
  const isLoggedIn = user?.id !== "";
  const inSubscription = user?.inSubscription;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else if (!inSubscription) {
      navigate("/subscription");
    }
  }, [isLoggedIn, inSubscription, navigate]);

  if (!isLoggedIn || !inSubscription) {
    return null;
  }

  return (
    <div className="bg-light">
      <TopNav />
      <InfoNav />
      <AllPosts />
    </div>
  );
}

export default Usersmain;
