import React from 'react'
import TopNav from '../../components/users/TopNav'
import CheckoutFormUser from '../../components/users/CheckoutFormUser'
import { useSelector } from "react-redux";

function CheckoutUser() {

  const userData = useSelector((state) => state.userDetails.user);

  return (
    <div>
      <TopNav/>
      <CheckoutFormUser packageValue={userData?.package} igUsername={userData?.defaultIgUsername} />
    </div>
  )
}

export default CheckoutUser
