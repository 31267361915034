import React from "react";

export function maskCreditCardNumber(ccNum) {
  if (!ccNum) return "";
  const maskedSection = ccNum.slice(0, -3).replace(/./g, "*");
  const visibleSection = ccNum.slice(-3);
  return `${maskedSection}${visibleSection}`;
}

function PaymentMethod({ userData }) {
  return (
    <div id="content-payment-methods" className="content content-settings">
      <div className="panel panel-pad">
        <h1>Billing</h1>
        <div className="panel-content">
          <table className="table">
            <thead className="hidden-xs hidden-sm">
              <tr>
                <th>Payment method</th>
                <th>Cardholder name</th>
                <th>Expires</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Card Number: <br />
                  <span>
                    {maskCreditCardNumber(userData?.billingInfo?.ccNum)}
                  </span>
                </td>
                <td>{userData?.billingInfo?.ccName}</td>
                <td>
                  {userData?.billingInfo?.ccMonth} /{" "}
                  {userData?.billingInfo?.ccYear}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
