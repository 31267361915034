import React from "react";

function FAQ() {
  return (
    <section id="faq" className="faq bg-white pt-2">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p>Frequently Asked Questions</p>
        </header>
        <div className="row">
          <div className="col-lg-6">
            <div className="accordion accordion-flush" id="faqlist1">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-content-1"
                  >
                    When will I receive my automatic Instagram likes?
                  </button>
                </h2>
                <div
                  id="faq-content-1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist1"
                >
                  <div className="accordion-body">
                    Our system monitors your account 24/7 and detects your
                    latest posts within 60 seconds. Likes start delivering to
                    your posts immediately after it is detected.{" "}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-content-2"
                  >
                    Can the likes I receive be spread out instead of delivered
                    all at once?
                  </button>
                </h2>
                <div
                  id="faq-content-2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist1"
                >
                  <div className="accordion-body">
                    Absolutely. You can adjust how fast or slow you want to
                    receive likes. Depending on your personal preference, speeds
                    range from Instant to 4 hours.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-content-3"
                  >
                    Do I need to give you my password?
                  </button>
                </h2>
                <div
                  id="faq-content-3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist1"
                >
                  <div className="accordion-body">
                    Absolutely not. Your password is completely confidential.
                    All we require is your username.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-content-4"
                  >
                    How do you deliver real likes?
                  </button>
                </h2>
                <div
                  id="faq-content-4"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist1"
                >
                  <div className="accordion-body">
                    Unlike most other services out there, we{" "}
                    <strong>do not</strong> use illegitimate methods to deliver
                    your likes. Whenever you share a new post, we show it to our
                    in-network users in real time. If they've opted in to our
                    Like Program, they'll give your post a like.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-content-5"
                  >
                    What is your refund policy?
                  </button>
                </h2>
                <div
                  id="faq-content-5"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist1"
                >
                  <div className="accordion-body">
                    If you are not completely satisfied within the first 30 days
                    of your new subscription, simply notify us and we will
                    refund you - no questions asked.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {/* F.A.Q List 2*/}
            <div className="accordion accordion-flush" id="faqlist2">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2-content-1"
                  >
                    Can my account stay private?
                  </button>
                </h2>
                <div
                  id="faq2-content-1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist2"
                >
                  <div className="accordion-body">
                    No, we cannot detect new posts on private accounts.
                    Therefore, users must have their profiles set to public in
                    order to receive automatic likes.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2-content-2"
                  >
                    How many of my posts will receive automatic likes?
                  </button>
                </h2>
                <div
                  id="faq2-content-2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist2"
                >
                  <div className="accordion-body">
                    To prevent abuse of our system, automatic like delivery is
                    limited to your first 4 posts per day. Don't worry, we have
                    alternative options for those who post more often. Simply
                    contact us for details.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2-content-3"
                  >
                    Can I receive likes on my older posts?
                  </button>
                </h2>
                <div
                  id="faq2-content-3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist2"
                >
                  <div className="accordion-body">
                    We do not automatically send likes to your older posts.
                    However, you may purchase credits through your
                    AutomaticViral dashboard and manually send likes to any of
                    your posts, including older ones.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2-content-4"
                  >
                    Will my account get banned or disabled?
                  </button>
                </h2>
                <div
                  id="faq2-content-4"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist2"
                >
                  <div className="accordion-body">
                    <b>
                      No, our system is 100% safe and will not get your account
                      banned or disabled.
                    </b>{" "}
                    Not one of our thousands of customers has ever been banned
                    as a result of using our services.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2-content-5"
                  >
                    How do I cancel my subscription?
                  </button>
                </h2>
                <div
                  id="faq2-content-5"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faqlist2"
                >
                  <div className="accordion-body">
                    Log into your AutomaticViral dashboard, go to Subscriptions
                    and click 'Manage Subscription'. Once you’ve cancelled your
                    subscription, you will not be billed again.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
