import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function SettingsHeader() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="settings-header">
      <ul className="settings-menu d-flex align-items-center justify-content-center">
        <li className={`settings-menu-item fs-4 ${location.pathname === "/my-settings" ? "settings-active" : ""}`}>
          <Link to="/my-settings">Account settings</Link>
        </li>
        <li className={`settings-menu-item fs-4 ms-2 ${location.pathname === "/my-billing" ? "settings-active" : ""}`}>
          <Link to="/my-billing">Billing</Link>
        </li>
        <li className={`settings-menu-item fs-4 ms-2 ${location.pathname === "/my-subscriptions" ? "settings-active" : ""}`}>
          <Link to="/my-subscriptions">Subscriptions</Link>
        </li>
        <li className="settings-logout">
          <a href="##" onClick={handleLogout}>
            <span className="d-flex align-items-center">
              <i className="bi bi-box-arrow-right fs-3 me-2 ms-sm-4 text-dark"></i>
              <span className="text-dark">Log out</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SettingsHeader;
