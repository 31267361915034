import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  activeusername: "",
  activeIgId: "",
  profilePic: "",
};

export const userActiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVEUSER:
      return {
        ...state,
        activeusername: action.payload.activeusername,
        activeIgId: action.payload.activeIgId,
        profilePic: action.payload.profilePic,
      };
    default:
      return state;
  }
};
