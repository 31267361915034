import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/userProfile";
import { setActiveUser } from "../redux/actions/activeUser";
import { setConnectedAccounts } from "../redux/actions/connectedAccounts";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import EmailValidator from "email-validator";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    igUsername: "",
    package: "",
    phone: "",
  });

  const isLoggedIn = localStorage.getItem("id") !== null;

  useEffect(() => {
    if (isLoggedIn) {
      const inSubscription = localStorage.getItem("inSubscription") === "true";
      navigate(inSubscription ? "/dashboard" : "/subscription");
    }
  }, [isLoggedIn, navigate]);

  const getConnectedAccounts = async (userId) => {
    const url = `${process.env.REACT_APP_API_URL}/user/connectedAccount/findByUserId`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ userId }),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      dispatch(setConnectedAccounts(data.data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    Object.keys(credentials).forEach((key) => {
      urlencoded.append(key, credentials[key]);
    });

    if (!EmailValidator.validate(credentials.email)) {
      toast.error(`Please enter a valid email address`);
      return;
    }

    if (credentials.phone.length < 10) {
      toast.error(`Phone number must be at least 10 characters`);
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/user/register`,
        requestOptions
      );
      const result = await response.json();
      if (result.status === "error") {
        toast.error(result.message);
        setIsLoading(false);
      } else if (result.status === "success") {
        const { data } = result;
        localStorage.setItem("id", data.id);
        localStorage.setItem("inSubscription", data.inSubscription.toString());
        dispatch(setUser(data));
        toast.success("Successfully Registered");
        dispatch(
          setActiveUser({
            activeusername: data.defaultIgUsername,
            activeIgId: data.defaultIgId,
            profilePic: data.profilePic,
          })
        );
        setIsLoading(false);
        getConnectedAccounts(data.id);
        setTimeout(() => {
          navigate(data.inSubscription ? "/dashboard" : "/subscription");
        }, 2500);
        setCredentials({
          name: "",
          email: "",
          password: "",
          igUsername: "",
          package: "",
          phone: "",
        });
      } else {
        console.log("Something went wrong.");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const onChange = (e) => {
    let val = e.target.value;

    if (e.target.name === "phone") {
      val = e.target.value.replace(/\D/g, "");
    }
    setCredentials({ ...credentials, [e.target.name]: val });
  };

  return (
    <div>
      <Helmet>
        <title>
          Sign Up for Automatic Viral | Start Your Automatic Likes Today
        </title>
        <meta
          name="description"
          content="Join Automatic Viral and start boosting your Instagram presence today with automatic likes. Gain real likes and followers automatically. No contract, cancel anytime. Sign up now to see instant results!"
        />
      </Helmet>
      <header className="d-flex flex-wrap justify-content-center">
        <Link
          to="/"
          className="w-100 p-4"
          style={{
            backgroundColor: "#3964e7",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="./assets/img/logo.svg"
            alt="logo"
            style={{ maxWidth: "100%", height: "45px" }}
          />
        </Link>
      </header>
      <section id="login" className="access center bg-white">
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mt-4 fw-bold" style={{ fontSize: 40 }}>
            Signup To Your Dashboard
          </h2>
          <form className="mx-md-5 mx-0 px-md-5 px-2" onSubmit={handleSubmit}>
            <div className="row mx-md-5 mx-0 px-md-5 px-2">
              <div className="col-md-6 col-sm-12 form-group mt-3">
                <div className="input-group">
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    value={credentials.name}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 form-group mt-3">
                <div className="input-group">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    value={credentials.email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 form-group">
                <div className="input-group">
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={onChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 form-group">
                <div className="input-group">
                  <input
                    name="igUsername"
                    type="text"
                    placeholder="Instagram Username"
                    value={credentials.igUsername}
                    onChange={onChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 form-group">
                <div className="input-group">
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={credentials.phone}
                    onChange={onChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <select
                  className="form-select form-select-lg mb-3 bg-white fs-4"
                  name="package"
                  style={{ height: "55px", width: "100%" }}
                  value={credentials.package}
                  onChange={onChange}
                >
                  <option value="">Select Package</option>
                  <option value="1">50 likes - 9.99$ /month</option>
                  <option value="2">100 likes - 17.99$ /month</option>
                  <option value="3">250 likes - 24.00$ /month</option>
                  <option value="4">500 likes - 48.00$ /month</option>
                  <option value="5">1000 likes - 75.00$ /month</option>
                  <option value="6">1500 likes - 99.00$ /month</option>
                  <option value="7">3000 likes - 189.00$ /month</option>
                </select>
              </div>
            </div>
            <div className="row mx-md-5 mx-0 px-md-5 px-0 mt-5">
              <div className="col-12 form-group d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-green fs-3 fw-semibold"
                  style={{ width: "100%" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="spinner-border border-3" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      Create Account
                      <span className="loading" />
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="row mx-md-5 mx-0 px-md-5 px-2 mt-5 d-flex justify-content-center">
              <p className="fw-bold fs-4 text-center">
                Already Have an Account?{" "}
                <Link to="/login" className="text-primary text-decoration-none">
                  Sign In Here
                </Link>
              </p>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Signup;
