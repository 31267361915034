import React from "react";
import TransactionHistory from "../../components/users/TransactionHistory";
import TopNav from "../../components/users/TopNav";
import SettingsHeader from "../../components/users/SettingsHeader";
import PaymentMethod from "../../components/users/PaymentMethod";
import { useSelector } from "react-redux";

function Billing() {

  const userData = useSelector((state) => state.userDetails.user);

  return (
    <>
      <TopNav />
      <SettingsHeader/>
      <div className="page container">
        <PaymentMethod userData={userData}/>
        <TransactionHistory userData={userData}/>
      </div>
    </>
  );
}

export default Billing;
