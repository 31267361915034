import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <section className="container text-dark mt-5 fs-4">
        <div className="text-center my-5">
          <div className="py-3">
            <h1 className="fw-bold">Privacy Policy</h1>
            <p>Published on May 18, 2018</p>
          </div>
        </div>
        <div className="section__body">
          <div className="shell shell--medium">
            <p>
              We take your privacy seriously and will take all measures to
              protect your personal information. Accordingly, we have developed
              this Policy in order for you to understand how we collect, use,
              communicate and disclose and make use of personal information. Any
              personal information received will only be used to fill your
              order. We will not sell or redistribute your information to
              anyone.
            </p>
            <p>
              We are committed to conducting our business in accordance with
              these principles in order to ensure that the confidentiality of
              personal information is protected and maintained.
            </p>
            <h4 className="fw-bold pb-2">The Basics</h4>
            <ol className="ms-5 pb-2">
              <li>
                Before or at the time of collecting personal information, we
                will identify the purposes for which information is being
                collected.
              </li>
              <li>
                We will collect and use personal information solely to fulfill
                those purposes specified by us and for other compatible purposes
                unless we obtain the consent of the individual concerned or as
                required by law.
              </li>
              <li>
                We will only retain personal information as long as necessary to
                fulfill those purposes.
              </li>
              <li>
                We will collect personal information by lawful and fair means
                and, where appropriate, with the knowledge or consent of the
                individual concerned.
              </li>
              <li>
                Personal data should be relevant to the purposes for which it is
                to be used and, to the extent necessary for those purposes,
                should be accurate, complete, and up-to-date.
              </li>
              <li>
                We will protect personal information by reasonable security
                safeguards against loss or theft, as well as unauthorized
                access, disclosure, copying, use, or modification.
              </li>
              <li>
                We will make readily available to customers information about
                our policies and practices relating to the management of
                personal information.
              </li>
              <li>
                Some payments are processed using PayPal’s secure system, while
                others are processed using a secure payment gateway, with no
                sensitive financial information being input through our website.
                The secure method of accepting payments is processed using SSL
                encryption.
              </li>
              <li>
                We may collect and store limited payment information from you,
                such as payment card type and expiration date and the last four
                digits of your payment card number; however, we do not collect
                or store full payment card numbers, and all transactions are
                processed by our third-party payment processor.
              </li>
            </ol>
            <h4 className="fw-bold pb-2">Use of Information</h4>
            <p>
              We use the information to provide, analyze, administer, enhance,
              and personalize our services and marketing efforts, process your
              registration, orders, and payments, and communicate with you on
              these and other topics. For example, we use the information to:
            </p>
            <ul className="ms-5 pb-2">
              <li>
                prevent, detect and investigate potentially prohibited or
                illegal activities, including fraud, and enforce our terms;
              </li>
              <li>
                analyze and understand our audience, improve our service
                (including our user interface experiences)
              </li>
            </ul>
            <h4 className="fw-bold pb-2">Google Analytics</h4>
            <p>
              This website uses Google Analytics, a web analytics service
              provided by Google, Inc. (“Google”).
            </p>
            <p>
              Google Analytics uses “cookies”, which are text files placed on
              your computer, to help the website analyze how users use the site.
              The information generated by the cookie about your use of the Site
              (including your IP address) will be transmitted to and stored by
              Google on servers in the U.S. If the IP anonymization is
              activated, Google will truncate/anonymize the last octet of the IP
              address for Member States of the European Union as well as for
              other parties to the Agreement on the European Economic Area. In
              exceptional cases, the full IP address is sent to and shortened by
              Google servers in the U.S.
            </p>
            <p>
              On behalf of the website provider Google will use this information
              for the purpose of evaluating your use of the Site, compiling
              reports on website activity for website operators, and providing
              other services relating to website activity and internet usage to
              the website provider. Google will not associate your IP address
              with any other data held by Google.
            </p>
            <p>
              You may refuse the use of cookies by selecting the appropriate
              settings on your browser. However, please note that if you do
              this, you may not be able to use the full functionality of this
              website. Furthermore, you can prevent Google’s collection and use
              of data (cookies and IP address) by downloading and installing the
              browser plug-in available under
            </p>
            <ul className="ms-5 pb-2">
              <li>
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
                  rel="nofollow"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=en-GB
                </a>
              </li>
            </ul>
            <p>
              Further information concerning the terms and conditions of use and
              data privacy can be found at:
            </p>
            <ul className="ms-5 pb-2">
              <li>
                <a
                  href="https://www.google.com/intl/en/policies/privacy/partners/"
                  rel="nofollow"
                >
                  https://www.google.com/intl/en/policies/privacy/partners/
                </a>
              </li>
              <li>
                <a
                  href="http://www.google.com/analytics/terms/gb.html"
                  rel="nofollow"
                >
                  http://www.google.com/analytics/terms/gb.html
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.de/intl/en_uk/policies/"
                  rel="nofollow"
                >
                  https://www.google.de/intl/en_uk/policies/
                </a>
              </li>
              <li>
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=en"
                  rel="nofollow"
                >
                  https://support.google.com/analytics/answer/6004245?hl=en
                </a>
              </li>
            </ul>
            <h4 className="fw-bold pb-2">Google Analytics Advertising</h4>
            <p>
              Google Analytics Advertising Features allows implementers to
              enable features in Analytics that aren’t available through
              standard implementations. This website has enabled Google
              Analytics Advertising features. Advertising features used by this
              website include:
            </p>
            <ul className="ms-5 pb-2">
              <li>Remarketing with Google Analytics</li>
              <li>Google Display Network Impression Reporting</li>
              <li>Google Analytics Demographics and Interest Reporting</li>
              <li>
                Integrated services that require Google Analytics to collect
                data for advertising purposes, including the collection of data
                via advertising cookies and identifiers
              </li>
            </ul>
            <p>
              By enabling the Advertising Features, implementers enable Google
              Analytics to collect data about user traffic via Google
              advertising cookies and identifiers, in addition to data collected
              through a standard Google Analytics implementation. Regardless of
              how implementers send data to Google Analytics (for example, via
              the Google Analytics tracking code, Google Analytics SDK, or the
              Measurement Protocol), if implementers use Google Advertising
              Features, they must adhere to this policy.
            </p>
            <p>
              We will not identify users or facilitate the merging of personally
              identifiable information with non-personally identifiable
              information collected through any Google advertising product or
              feature unless we have robust notice of, and the user’s prior
              affirmative (i.e., opt-in) consent to, that identification or
              merger, and are using a Google Analytics feature that expressly
              supports such identification or merger. Irrespective of users’
              consent, an attempt to disaggregate data that Google reports in
              aggregate will not be made.
            </p>
            <h4 className="fw-bold pb-2">Disclosure of Information </h4>
            <p>
              We use other companies, agents or contractors (“Service
              Providers”) to perform services on our behalf or to assist us with
              the provision of services to you. For example, we engage Service
              Providers to provide marketing, advertising, communications,
              infrastructure and IT services, to personalize and optimize our
              service, to process credit card transactions or other payment
              methods, to provide customer service, to collect debts, to analyze
              and enhance data (including data about users’ interactions with
              our service), and to process and administer consumer surveys. In
              the course of providing such services, these Service Providers may
              have access to your personal or other information. We do not
              authorize them to use or disclose your personal information except
              in connection with providing their services.
            </p>
            <h4 className="fw-bold pb-2">Cookies</h4>
            <p>
              A cookie is an element of data that a website can send to your
              browser, which may then be stored on your system. This website
              uses cookies to enhance your experience on our website. You can
              set your browser to notify you when you receive a cookie, giving
              you the chance to decide whether to accept it. No Personal
              Information is stored in these cookies. Our use of cookies simply
              identifies you as you move throughout the Site, providing faster,
              personalized access to view various site pages.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
