import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveUser } from "../../redux/actions/activeUser";
import { BASE_URL } from "../../config/constants";
// import ManageAccountModal from "./ManageAccountModal";
import SubscriptionModal from "./SubscriptionModal";

function InfoNav() {
  const dispatch = useDispatch();

  const activeUserData = useSelector((state) => state.userActiveReducer);
  const connectedAccounts = useSelector(
    (state) => state.userConnectedAccounts.connectedAccounts
  );

  const [activeConnectedAccount, setActiveConnectedAccount] = useState(null);

  useEffect(() => {
    setActiveConnectedAccount(
      connectedAccounts.filter(
        (acc) => acc.igUsername === activeUserData.activeusername
      )[0]
    );
  }, [activeUserData, connectedAccounts]);

  async function updateActiveUser({ igId, igUsername, profilePic }) {
    dispatch(
      setActiveUser({
        activeusername: igUsername,
        activeIgId: igId,
        profilePic: profilePic,
      })
    );
  }

  return (
    <div id="dashboard">
      <div id="userSubNav" className="topbar active open">
        <div className="user-sub container active">
          <div className="profile">
            <span className="avatar">
              <img
                src={`${BASE_URL}/proxy/${activeUserData.profilePic}`}
                crossOrigin="anonymous"
                alt="Profile Pic"
              />
            </span>
            <span className="info">
              <div className="user-sub-list dropdown overlay">
                <a href="##" data-toggle="dropdown">
                  <span className="username">
                    {activeUserData.activeusername}
                    <i className="bi bi-caret-down-fill fs-3"></i>
                  </span>
                </a>
                <ul className="dropdown-menu">
                  {connectedAccounts?.map((account, index) => {
                    return (
                      <li key={index} className="user-sub active">
                        <a
                          href="##"
                          onClick={() => updateActiveUser(account)}
                          className="profile current"
                        >
                          <div className="avatar">
                            <img
                              src={`${BASE_URL}/proxy/${account.profilePic}`}
                              crossOrigin="anonymous"
                              alt="icon"
                            />
                          </div>
                          <div className="info">
                            <span className="username">
                              {account?.igUsername}
                            </span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                  <li role="separator" className="divider"></li>
                  <li className="add">
                    <a
                      href="##"
                      className="btn btn-green"
                      data-toggle="modal"
                      data-target="#addUserSubModal"
                    >
                      <span>
                        {" "}
                        <i className="bi bi-plus-lg me-3 fs-2"></i>Add new
                        account
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <a
                href="##"
                className="subscription"
                data-toggle="modal"
                data-target="#subscriptionmodal"
              >
                <i
                    style={{ color: "black !important" }}
                    className="fs-3 bi bi-gear-fill"
                  ></i>
                  <span className="ms-2">Manage</span>
                </a>
              </span>
            </div>


        </div>
        
        <SubscriptionModal account={activeConnectedAccount} />
      </div>
    </div>
  );
}

export default InfoNav;
