import React, { useEffect, useState } from "react";
import SinglePost from "./SinglePost";
import { useSelector } from "react-redux";
import Loader from "../common/Loader";

function AllPosts() {
  const activeUserData = useSelector((state) => state.userActiveReducer);

  const [postsData, setPostsData] = useState([]);

  useEffect(() => {
    const getPostsByIgId = async (igId) => {
      const url = `${process.env.REACT_APP_API_URL}/user/instagram/getPostsByIgId?igId=${igId}`;
      const options = {
        method: "GET",
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log(data)
        setPostsData(data.data.items);
      } catch (error) {
        console.error(error);
      }
    };
    getPostsByIgId(activeUserData?.activeIgId);
  }, [activeUserData]);

  console.log(postsData);

  const updatePostLikes = (postId, additionalLikes) => {
    setPostsData((prevPosts) =>
      prevPosts.map((post) =>
        post.node.id === postId
          ? {
              ...post,
              node: {
                ...post.node,
                totalLikesPurchased:
                  (post.node.totalLikesPurchased || 0) + additionalLikes,
              },
            }
          : post
      )
    );
  };

  return (
    <div className="page container">
      <div className="content">
        <div id="postContainer">
          <div className="post-overlay"></div>
          {postsData === undefined ? "Site is under maintainance, Please check back soon." : ""}
          {postsData?.length === 0 ? (
            <Loader />
          ) : (
            postsData?.map((post, index) => (
              <SinglePost
                postData={post.node}
                key={index}
                setPostsData={setPostsData}
                updatePostLikes={updatePostLikes}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default AllPosts;
