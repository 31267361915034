import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setUser } from "../../redux/actions/userProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function CheckoutForm() {
  const MySwal = withReactContent(Swal);

  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const selectedData = location?.state.selectedPackage;
  const userData = useSelector((state) => state.userDetails.user);
  const [isLoading, setIsLoading] = useState(false);

  const [credentials, setCredentials] = useState({
    ccNum: userData?.billingInfo?.ccNum || "",
    ccName: userData?.name || "",
    ccMonth: userData?.billingInfo?.ccMonth || "",
    ccYear: userData?.billingInfo?.ccYear || "",
    cvv: "",
    amount: selectedData.price,
    credits: selectedData.credits,
    userId: userData.id,
    country: "US",
    address: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
    phone: userData?.phone || "",
    ipAddress: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check credit card max numbers
    if (name === "ccNum") {
      if (value.length > 16) {
        toast.error("Credit card number max length reached", {
          toastId: "ccNumMax",
        });
        return;
      }
    }

    // Validate the month and year if both are provided
    const updatedCredentials = { ...credentials, [name]: value };
    const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based
    const currentYear = new Date().getFullYear();

    if (name === "ccMonth" || name === "ccYear") {
      const selectedMonth = parseInt(updatedCredentials.ccMonth, 10);
      const selectedYear = parseInt(updatedCredentials.ccYear, 10);

      if (updatedCredentials.ccYear && updatedCredentials.ccMonth) {
        if (
          selectedYear < currentYear ||
          (selectedYear === currentYear && selectedMonth < currentMonth)
        ) {
          toast.error("The selected date must be in the future.");
          return;
        }
      }
    }

    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {

    async function getIPAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Your IP address is:", data.ip);
        setCredentials(c => {
          return {
            ...c,
            ipAddress: data.ip,
          }
        })
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    }

    getIPAddress();
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/user/creditsTransaction/addRecord`;

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...credentials,
        "-state": credentials.state,
        "-apartment": credentials.apartment,
      }),
    };
    setIsLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        setIsLoading(false);
        dispatch(setUser(data.data));
        MySwal.fire({
          title: "Payment Successful",
          html: `You will be redirected… if not <a href="/dashboard">click here</a>`,
          icon: "success",
          timer: 3000,
        }).then(() => {
          navigate("/dashboard");
        });
        setCredentials({
          ccNum: "",
          ccName: "",
          ccMonth: "",
          ccYear: "",
          cvv: "",
          amount: selectedData.price,
          credits: selectedData.credits,
          userId: userData.id,
          country: "",
          address: "",
          apartment: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        });
      } else {
        setIsLoading(false);
        toast.error("Unable to process payment");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to process payment");

      console.error("Failed to fetch", error);
    }
  };

  return (
    <div id="checkout" className="content content-checkout">
      <div className="panel panel-pad panel-right">
        <div className="summary">
          <div className={`summary-toggle ${toggle ? "open" : ""}`}>
            <div
              className="d-flex align-items-center"
              onClick={() => setToggle(!toggle)}
              style={{ cursor: "pointer" }}
            >
              <img className="menu" src="/assets/img/menu.svg" alt="menu" />
              <span className="ms-3">Show summary</span>
            </div>
            <div>${selectedData.price}</div>
          </div>
          <div className="summary-content">
            <table className="summary-table">
              <thead className="hidden-xs hidden-sm">
                <tr>
                  <th className="title" colspan="2">
                    Order summary
                  </th>
                </tr>
                <tr>
                  <th colspan="2">
                    <a
                      href="##"
                      className="navbar-credits"
                      data-bs-toggle="modal"
                      data-bs-target="#creditsModal"
                    >
                      {" "}
                      <i className="bi bi-pencil-square me-3 fs-4"></i>
                      <span className="user-credits">Change Package</span>
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Credits package</td>
                  <td>{selectedData.credits} Credits</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>
                    <span>$</span>
                    {selectedData.price}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>
                    $<span className="total">{selectedData.price}</span>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="my-5 pt-5 d-flex flex-column gap-4">
            <div className="d-flex align-items-center gap-4 border-top pt-4">
              <i class="bi bi-person text-secondary fs-1"></i>
              <p className="fs-3 mb-0">
                <span className="fw-bold">Real likes </span>from real people
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i class="bi bi-images text-secondary fs-1"></i>
              <p className="fs-3 mb-0">
                <span className="fw-bold">Split likes </span>on multiple posts
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i class="bi bi-eye text-secondary fs-1"></i>
              <p className="fs-3 pb-0">
                <span className="fw-bold">Video views </span>included
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i class="bi bi-lock text-secondary fs-1"></i>
              <p className="fs-3 pb-0">
                <span className="fw-bold">No Instagram password </span>required
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i class="bi bi-speedometer2 text-secondary fs-1"></i>
              <p className="fs-3 pb-0">
                <span className="fw-bold">Fast delivery</span>, up to 10 mins
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i class="bi bi-clock text-secondary fs-1"></i>
              <p className="fs-3 pb-0">
                <span className="fw-bold">24/7 </span>support
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-pad panel-left">
        <div className="form checkout-form">
          <div className="form-header">
            <div className="title">Secure checkout</div>
            <div className="billing-features">
              <div className="billing-feature">
                <span className="icon">
                  <img src="/assets/img/moneyback.svg" alt="moneyback" />
                </span>
                <span className="message">
                  <em
                    data-toggle="tooltip"
                    title=""
                    data-original-title="If for any reason you are not satisfied with our service within 30 days of signing up, contact us for a full refund - no questions asked."
                  >
                    30 day money back guarantee
                  </em>
                </span>
              </div>
              <div className="billing-feature">
                <span className="icon">
                  <img src="/assets/img/cancel-anytime.svg" alt="cancle" />
                </span>
                <span className="message">Cancel anytime</span>
              </div>
            </div>{" "}
          </div>
          {/* <div className="secure">
              <img
                className="seal nortonButton"
                alt="Seal"
                src="https://seal.websecurity.norton.com/getseal?at=0&amp;sealid=3&amp;dn=automaticviral.com&amp;lang=en&amp;tpt=transparent"
                style={{ cursor: "pointer" }}
              />
            </div> */}
          <hr />
          <div className="billing-info">
            <div className="form-group">
              <div className="form-group-header">
                <span className="sub-title">Pay with credit / debit card</span>
                <div className="billing-features">
                  <div className="billing-feature">
                    <span className="icon">
                      <img src="/assets/img/moneyback.svg" alt="moneyback" />
                    </span>
                    <span className="message">
                      <em
                        data-toggle="tooltip"
                        title=""
                        data-original-title="If for any reason you are not satisfied with our service within 30 days of signing up, contact us for a full refund - no questions asked."
                      >
                        30 day money back guarantee
                      </em>
                    </span>
                  </div>
                  <div className="billing-feature">
                    <span className="icon">
                      <img src="/assets/img/cancel-anytime.svg" alt="cancle" />
                    </span>
                    <span className="message">Cancel anytime</span>
                  </div>
                </div>{" "}
                <span className="sub-title-info card-list">
                  <span className="card card-visa"></span>
                  <span className="card card-mastercard"></span>
                  <span className="card card-discover"></span>
                  <span className="card card-amex"></span>
                </span>
              </div>
              <div className="input-group input-group-name validation-group">
                <input
                  type="text"
                  name="ccName"
                  value={credentials.ccName}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Cardholder name"
                  aria-label="Cardholder name"
                  required=""
                />
                <label for="card-name">
                  <span className="placeholder">Cardholder name</span>
                </label>
                <span className="validation-message required">
                  Please enter a cardholder name
                </span>
                <span className="validation-message invalid">
                  Cardholder name is invalid
                </span>
              </div>
              <div className="input-group input-group-number validation-group">
                <input
                  type="number"
                  name="ccNum"
                  value={credentials.ccNum}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Card number"
                  aria-label="Card number"
                  required
                  autoComplete="off"
                />
                <label for="card-number">
                  <span className="placeholder">Card number</span>
                </label>
                <span className="icon icon-lock"></span>
                <span className="validation-message required">
                  Please enter a card number
                </span>
                <span className="validation-message invalid">
                  Card number is invalid
                </span>
              </div>
              <div className="input-group input-select input-group-month validation-group">
                <select
                  name="ccMonth"
                  value={credentials.ccMonth}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Month"
                  required
                >
                  <option value="" disabled="" selected=""></option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <label for="card-month">
                  <span className="placeholder">Month</span>
                </label>
                <span className="validation-message required">
                  Please enter a month
                </span>
              </div>
              <div className="input-group input-select input-group-year validation-group">
                <select
                  id="card-year"
                  name="ccYear"
                  value={credentials.ccYear}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Year"
                  aria-label="Year"
                  required
                >
                  <option value="" disabled="" selected=""></option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                </select>
                <label for="card-year">
                  <span className="placeholder">Year</span>
                  <span className="validation-message"></span>
                </label>
                <span className="validation-message required">
                  Please enter a year
                </span>
              </div>
              <div className="input-group input-group-code validation-group">
                <input
                  id="card-code"
                  type="number"
                  name="cvv"
                  value={credentials.cvv}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                  autocomplete="off"
                  className="form-control mask"
                  placeholder="CVV/CVC"
                  aria-label="CVV/CVC"
                  pattern="[0-9]*"
                  maxLength={4}
                  minLength={4}
                  required
                />
                <label for="card-code">
                  <span className="placeholder">CVV/CVC</span>
                </label>
                <span className="validation-message required">
                  Please enter a CVV/CVC code
                </span>
                <span className="validation-message invalid">
                  CVV/CVC code is invalid
                </span>
              </div>
            </div>
            <hr />
            <div className="form-group">
              <div className="form-group-header">
                <span className="sub-title">Billing address</span>
              </div>
              <div className="input-group input-group-address validation-group">
                <input
                  id="bill-address"
                  type="text"
                  name="address"
                  value={credentials.address}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Street Address"
                  aria-label="Street Address"
                  required
                />
                <label for="bill-address">
                  <span className="placeholder">Street Address</span>
                </label>
                <span className="validation-message required">
                  Please enter an address
                </span>
              </div>
              <div className="input-group input-group-address2">
                <input
                  id="bill-address2"
                  type="text"
                  name="apartment"
                  value={credentials.apartment}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Apartment, suite, etc. (optional)"
                  aria-label="Apartment, suite, etc. (optional)"
                />
                <label for="bill-address2">
                  <span className="placeholder">
                    Apartment, suite, etc. (optional)
                  </span>
                </label>
              </div>
              <div className="input-group input-group-zip validation-group">
                <input
                  id="bill-zip"
                  type="number"
                  name="zip"
                  value={credentials.zip}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Zip Code"
                  aria-label="Zip Code"
                  required
                />
                <label for="bill-zip">
                  <span className="placeholder">Zip Code</span>
                </label>
                <span className="validation-message required">
                  Please enter a zip/postal code
                </span>
              </div>
              <div className="input-group input-select input-group-state validation-group">
                <input
                  id="bill-zip"
                  type="text"
                  name="state"
                  value={credentials.state}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="State"
                  aria-label="State"
                />
                <label for="bill-state">
                  <span className="placeholder">State</span>
                </label>
                <span className="validation-message required">
                  Please enter a state
                </span>
              </div>
              <div className="input-group input-group-city validation-group">
                <input
                  id="bill-city"
                  type="text"
                  name="city"
                  value={credentials.city}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="City"
                  aria-label="City"
                  required
                />
                <label for="bill-city">
                  <span className="placeholder">City</span>
                </label>
                <span className="validation-message required">
                  Please enter a city/town
                </span>
              </div>
              <div className="input-group input-select validation-group input-group-country">
                <select
                  id="bill-country"
                  name="country"
                  value={credentials.country}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Country"
                  aria-label="Country"
                  required=""
                >
                  <option value="" disabled="" selected=""></option>
                  <option value="US">United States</option>
                  <option value="AR">Argentina</option>
                  <option value="AU">Australia</option>
                  <option value="BR">Brazil</option>
                  <option value="CA">Canada</option>
                  <option value="CO">Colombia</option>
                  <option value="HR">Croatia</option>
                  <option value="DK">Denmark</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JP">Japan</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MX">Mexico</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NO">Norway</option>
                  <option value="RU">Russia</option>
                  <option value="SG">Singapore</option>
                  <option value="ZA">South Africa</option>
                  <option value="ES">Spain</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                </select>
                <label for="bill-country">
                  <span className="placeholder">Country</span>
                </label>
                <span className="validation-message required">
                  Please enter a country
                </span>
              </div>
              <div className="input-group input-group-phone validation-group">
                <input
                  id="bill-phone"
                  type="number"
                  name="phone"
                  value={credentials.phone}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Phone"
                  aria-label="Phone"
                  required
                />
                <label for="bill-phone">
                  <span className="placeholder">Phone number</span>
                </label>
                <span className="validation-message required">
                  Please enter a phone number
                </span>
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success btn-block btn-submit-cc mx"
            data-payment="card"
            data-saved="false"
            data-setup="credits"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="spinner-border border-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                Confirm &amp; Pay<span className="loading"></span>
              </>
            )}
          </button>
          <div className="statement">
            By completing your order, you agree to the
            <br />
            <a href="/terms-of-service" target="_blank">
              {" "}
              terms of service
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" target="_blank">
              privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
