import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section id="services" className="services bg-white pt-5">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p>How it works</p>
        </header>
        <div className="row gy-4">
          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="service-box blue d-flex align-items-center flex-column">
              <img src="./assets/img/work1.png" alt="" height={250} />
              <h3 className="mt-5">Just upload a picture or video to your Instagram account</h3>
              <Link to="/signup" className="read-more">
                <span>Sign Up</span> <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <div className="service-box orange d-flex align-items-center flex-column">
              <img src="./assets/img/work2.png" alt="" height={250} />
              <h3 className="mt-5">Your new media is automatically detected</h3>
              <Link to="/signup" className="read-more">
                <span>Sign Up</span> <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <div className="service-box green d-flex align-items-center flex-column">
              <img src="./assets/img/work3.png" alt="" height={250} />
              <h3 className="mt-5">Watch your likes grow</h3>
              <Link to="/signup" className="read-more">
                <span>Sign Up</span> <i className="bi bi-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
