import moment from "moment";
import React from "react";
import { maskCreditCardNumber } from "./PaymentMethod";

function TransactionHistory({ userData }) {
  console.log(userData.creditsTransactions)
  return (
    <div id="content-transactions" className="content content-settings">
      <div className="panel panel-pad">
        <h1>Transaction history</h1>

        <div className="panel-content">
          <table className="table">
            <thead className="hidden-xs hidden-sm">
              <tr>
                <th>Date</th>
                <th>Username</th>
                <th>Description</th>
                <th>Payment method</th>
                <th className="right">Total</th>
              </tr>
            </thead>
            <tbody>
              {userData?.creditsTransactions?.length <= 0 ? (
                <tr>
                  <td colspan="5" className="text-center">
                    <em>No transactions were found.</em>
                  </td>
                </tr>
              ) : (
                userData?.creditsTransactions?.map((trans, index) => (
                  <tr key={index}>
                    <td>{moment(trans.createdAt).format('MMMM DD, YYYY')}</td>
                    <td>{trans.igUsername ? trans.igUsername : "-----"}</td>
                    <td>{trans.description}</td>
                    <td>
                      Card Number: {maskCreditCardNumber(trans.billingInfoId.ccNum)} <br />
                      CardHolder Name: {trans.billingInfoId.ccName} <br />
                      Expiry: {trans.billingInfoId.ccMonth} / {trans.billingInfoId.ccYear}
                    </td>
                    <td className="right">${trans.price}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
