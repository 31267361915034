import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Usersmain from "./pages/user/Usersmain";
import AccountSettings from "./pages/user/AccountSettings";
import Billing from "./pages/user/Billing";
import Subscriptions from "./pages/user/Subscriptions";
import Signup from "./pages/Signup";
import TermsofService from "./pages/TermsofService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CheckoutCredits from "./pages/user/CheckoutCredits";
import CheckoutUser from "./pages/user/CheckoutUser";
import OtherSubscription from "./pages/user/OtherSubscription";
// import ProtectedRoute from "./components/main/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmail from "./pages/VerifyEmail";
import ConfirmOTP from "./pages/ConfirmOtp";
import ChangePassword from "./pages/ChangePassword";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/confirm-otp" element={<ConfirmOTP />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/terms-of-service" element={<TermsofService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/dashboard" element={<Usersmain />} />
          <Route path="/subscription" element={<CheckoutUser />} />
          <Route path="/my-settings" element={<AccountSettings />} />
          <Route path="/my-billing" element={<Billing />} />
          <Route path="/my-subscriptions" element={<Subscriptions />} />
          <Route path="/payment/credits" element={<CheckoutCredits />} />
          <Route
            path="/other-account-subscription"
            element={<OtherSubscription />}
          />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
