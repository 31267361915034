import React from "react";
import TopNav from "../../components/users/TopNav";
import SettingsHeader from "../../components/users/SettingsHeader";
import SubscriptionTable from "../../components/users/SubscriptionTable";
function Subscriptions() {
  return (
    <>
      <TopNav />
      <SettingsHeader />
      <div className="page container">
        <div id="content-subs" className="content content-settings">
          <div className="panel panel-pad">
            <h1>Subscriptions</h1>

            <SubscriptionTable />

            <div className="add">
              <a
                href="##"
                className="btn btn-success d-flex align-items-center justify-content-center"
                data-toggle="modal"
                data-target="#addUserSubModal"
                style={{ margin: "5px" }}
              >
                <i className="bi bi-plus-circle mr-2 me-2"></i>
                <span>Add account</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscriptions;
