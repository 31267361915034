import React from "react";

function UpdatePassword() {
  return (
    <form method="post" action="/" className="form typeUnlock">
      <div className="form-title">Change password</div>
      <div className="form-group">
        <div className="input-group">
          <input
            id="currentPassword"
            name="currentPassword"
            type="password"
            className="form-control placeholder-shown"
            placeholder="Current password"
            required=""
            min=""
          />
        </div>
        <div className="input-group">
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            className="form-control placeholder-shown"
            placeholder="New password"
            required=""
          />
        </div>
        <div className="input-group">
          <input
            id="confirmPassword"
            name="confirmNewPassword"
            type="password"
            className="form-control placeholder-shown"
            placeholder="Confirm new password"
            required=""
          />
        </div>
      </div>
      <button type="submit" className="btn btn-green mx" disabled="">
        Save password
      </button>
    </form>
  );
}

export default UpdatePassword;
